// api/axios.js
import axios from 'axios';

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_SERVICE,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    }

});

// Add request interceptor for debugging
apiClient.interceptors.request.use(request => {

  console.log('Starting Request:', request);
    return request;
});

// Auth header interceptor
apiClient.interceptors.request.use(request => {
  const token = localStorage.getItem('auth_token');
  if (token) {
    request.headers['Authorization'] = `Bearer ${token}`;
  }
  return request;
});
// Add response interceptor for debugging
apiClient.interceptors.response.use(
    response => {
        console.log('Response:', response);
        return response;
    },
    error => {
        console.error('Response Error:', error.response || error);
        return Promise.reject(error);
    }
);

export default apiClient;

