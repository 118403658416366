<template>
    <div class="box">
        <div class="columns">
            <div class="column is-two-fifths has-text-left">
                <label class="label">Therapeutic Drug Monitoring/Pharmacokinetics (TDM/PK) performed</label>
            </div>
            <div class="column is-three-fifths">
                <input class="is-checkradio" id="therapeuticDrugMonitoringYes" type="radio" value="Yes" v-model="therapeuticDrugMonitoring" name="therapeuticDrugMonitoringYes">
                <label for="therapeuticDrugMonitoringYes">Yes</label>
                <input class="is-checkradio" id="therapeuticDrugMonitoringNo" type="radio" value="No" v-model="therapeuticDrugMonitoring" name="therapeuticDrugMonitoringNo">
                <label for="therapeuticDrugMonitoringNo">No</label>
                <input class="is-checkradio" id="therapeuticDrugMonitoringunknown" type="radio" value="unknown" v-model="therapeuticDrugMonitoring" name="therapeuticDrugMonitoringunknown">
                <label for="therapeuticDrugMonitoringunknown">unknown</label>
            </div>
        </div>
        <div class="columns" v-if="therapeuticDrugMonitoring=='Yes'">
            <div class="column is-two-fifths has-text-left">
                <label class="label">If TDM/PK performed, specify for which drugs</label>
            </div>
            <div class="column is-three-fifths">
                  <textarea class="textarea" placeholder="Specify drugs." v-model="TDMPKDrugs"></textarea>
            </div>            
        </div>
        <div class="columns" v-if="therapeuticDrugMonitoring=='Yes'">
            <div class="column is-two-fifths has-text-left">
                <label class="label">Additional Comments</label>
            </div>
            <div class="column is-three-fifths">
                  <textarea class="textarea" placeholder="Add your comments." v-model="TDMPKComments"></textarea>
            </div>            
        </div>
    </div>
</template>

<script>
export default {
    name:'TherapeuticMonitoring',
    data(){
        return{
            therapeuticDrugMonitoring: '',
            TDMPKDrugs: '',
            TDMPKComments: ''
        }
    }
}
</script>