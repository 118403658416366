<template>
    <div>
     <div class="box">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Previous TB Episode:</label>
            </div>
            <div class="field-body">
              <div class="field">
                <input class="is-checkradio" id="previousTB1" type="radio" value="Yes" v-model="previousTB" name="previousTB">
                <label for="previousTB1">Yes</label>
                <input class="is-checkradio" id="previousTB2" type="radio" value="No" v-model="previousTB" name="previousTB">
                <label for="previousTB2">No</label>
                <input class="is-checkradio" id="previousTB3" type="radio" value="Unknown" v-model="previousTB" name="previousTB">
                <label for="previousTB3">Unknown</label>
              </div>
            </div>
          </div>
          <div class="field is-horizontal" v-if="previousTB=='Yes'">
            <div class="field-label is-normal">
              <label class="label">Type:</label>
            </div>
            <div class="field-body">
              <div class="field">
                <input class="is-checkradio" id="previousTBType1" type="checkbox" value="Yes" v-model="previousTBTypePulmonary" name="previousTBTypePulmonary">
                <label for="previousTBType1">Pulmonary</label>
              </div>
              <div class="field">
                <input class="is-checkradio" id="previousTBType2" type="checkbox" value="Yes" v-model="previousTBTypeExtraPulmonary" name="previousTBTypeExtraPulmonary">
                <label for="previousTBType2">Extra Pulmonary</label>
              </div>
              <div class="field">
                <label v-if="previousTBTypeExtraPulmonary == 'Yes'" for="previousTBTypeExtraPulmonarySpec">please specify:</label>
                <input class="input" id="previousTBTypeExtraPulmonarySpec" v-model="previousTBTypeExtraSpec" name="previousTBTypeExtraPulmonarySpec" v-if="previousTBTypeExtraPulmonary"> 
              </div>
            </div>
          </div>
          <div class="field is-horizontal" v-if="previousTB=='Yes'">
            <div class="field-label is-normal">
              <label class="label"> Resistance Status:</label>
            </div>
            <div class="field-body">
              <div class="field has-text-left">
              <p>
                <input class="is-checkradio" id="previousTBResistance1" type="radio" value="Susceptible" v-model="previousTBResistance" name="previousTBResistance">
                <label for="previousTBResistance1">Susceptible</label>
              </p>
              <p>
                <input class="is-checkradio" id="previousTBResistance2" type="radio" value="Mono-resistant" v-model="previousTBResistance" name="previousTBResistance">
                <label for="previousTBResistance2">Mono-resistant</label>
              </p>
              <p>
                <input class="is-checkradio" id="previousTBResistance3" type="radio" value="Poly-resistant" v-model="previousTBResistance" name="previousTBResistance">
                <label for="previousTBResistance3">Poly-resistant</label>
              </p>
              <p>
                <input class="is-checkradio" id="previousTBResistance4" type="radio" value="MDR-TB" v-model="previousTBResistance" name="previousTBResistance">
                <label for="previousTBResistance4">MDR-TB</label>
              </p>
              </div>
              <div class="field has-text-left">
                <p>
                  <input class="is-checkradio" id="previousTBResistance5" type="radio" value="pre-XDR-TB" v-model="previousTBResistance" name="previousTBResistance">
                  <label for="previousTBResistance5">pre-XDR-TB</label>
                </p>
                <p>
                <input class="is-checkradio" id="previousTBResistance6" type="radio" value="XDR-TB" v-model="previousTBResistance" name="previousTBResistance">
                <label for="previousTBResistance6">XDR-TB</label>
                </p>
                <p>
                <input class="is-checkradio" id="previousTBResistance7" type="radio" value="Not classifiable" v-model="previousTBResistance" name="previousTBResistance">
                <label for="previousTBResistance7">Not classifiable</label>
                </p>
              </div>
            </div>
          </div>
          <div class="field is-horizontal" v-if="previousTB=='Yes'">
            <div class="field-label is-normal">
              <label class="label">Prior anti-TB<br/> therapy >30 days:</label>
            </div>
            <div class="field-body">
              <div class="field">
                <input class="is-checkradio" id="previousTBTerapy2" type="radio" value="No" v-model="previousTBTerapy" name="previousTBTerapy">
                <label for="previousTBTerapy2">No</label>
                <input class="is-checkradio" id="previousTBTerapy1" type="radio" value="Yes" v-model="previousTBTerapy" name="previousTBTerapy">
                <label for="previousTBTerapy1">Yes</label>
              </div>
            </div>
          </div>

          <div class="field is-horizontal" v-if="previousTBTerapy == 'Yes'">
            <div class="field-label is-normal">
                Specify number of prior TB Therapies > 30 days:
            </div>
            <div class="field-body">
              <div class="field">
                <div class="select">
                    <select name="" id="input" class="form-control" v-model="selectTherapyDay" >
                      <option v-for="n in getNumbers(1,50)" :value="n"  v-bind:key="n">{{n}}</option>
                    </select>
                </div>

              </div>
            </div>
          </div> 
          <div class="field is-horizontal" v-if="previousTBTerapy=='Yes'">
            <div class="field-label is-normal">
                Specify anti-TB drugs of the last prior regimen:
            </div>
            <div class="field-body">
              <div class="field has-text-left">
                <p>
                  <input class="is-checkradio" id="previousDrugsINH" type="checkbox" v-model="PreviousDrugs.INH" name="previousDrugsINH">
                  <label for="previousDrugsINH">INH</label>
                </p>
                <p>
                  <input class="is-checkradio" id="previousDrugsRMP" type="checkbox" v-model="PreviousDrugs.RMP" name="previousDrugsRMP">
                  <label for="previousDrugsRMP">RMP</label>
                </p>
                <p>
                  <input class="is-checkradio" id="previousDrugsETB" type="checkbox" v-model="PreviousDrugs.ETB" name="previousDrugsETB">
                  <label for="previousDrugsETB">ETB</label>
                </p>
                <p>
                  <input class="is-checkradio" id="previousDrugsStrepto" type="checkbox" v-model="PreviousDrugs.Strepto" name="previousDrugsStrepto">
                  <label for="previousDrugsStrepto">Strepto</label>
                </p>
                <p>
                  <input class="is-checkradio" id="previousDrugsPZA" type="checkbox" v-model="PreviousDrugs.PZA" name="previousDrugsPZA">
                  <label for="previousDrugsPZA">PZA</label>
                </p>
                <p>
                  <input class="is-checkradio" id="previousDrugsAMK" type="checkbox" v-model="PreviousDrugs.AMK" name="previousDrugsAMK">
                  <label for="previousDrugsAMK">AMK</label>
                </p>
              </div>
              <div class="field has-text-left">
                <p>
                  <input class="is-checkradio" id="previousDrugsKANA" type="checkbox" v-model="PreviousDrugs.KANA" name="previousDrugsKANA">
                  <label for="previousDrugsKANA">KANA</label>
                </p>
                <p>
                  <input class="is-checkradio" id="previousDrugsCAPREO" type="checkbox" v-model="PreviousDrugs.CAPREO" name="previousDrugsCAPREO">
                  <label for="previousDrugsCAPREO">CAPREO</label>
                </p>
                <p>
                  <input class="is-checkradio" id="previousDrugsOFX" type="checkbox" v-model="PreviousDrugs.OFX" name="previousDrugsOFX">
                  <label for="previousDrugsOFX">OFX</label>
                </p>
                <p>
                  <input class="is-checkradio" id="previousDrugsLFK" type="checkbox" v-model="PreviousDrugs.LFK" name="previousDrugsLFK">
                  <label for="previousDrugsLFK">LFK</label>
                </p>
                <p>
                  <input class="is-checkradio" id="previousDrugsLFXHD" type="checkbox" v-model="PreviousDrugs.LFXHD" name="previousDrugsLFXHD">
                  <label for="previousDrugsLFXHD">LFX HD</label>
                </p>
                <p>
                  <input class="is-checkradio" id="previousDrugsMFX" type="checkbox" v-model="PreviousDrugs.MFX" name="previousDrugsMFX">
                  <label for="previousDrugsMFX">MFX</label>
                </p>
              </div>
              <div class="field has-text-left">
                <p>
                  <input class="is-checkradio" id="previousDrugsMFXHD" type="checkbox" v-model="PreviousDrugs.MFXHD" name="previousDrugsMFXHD">
                  <label for="previousDrugsMFXHD">MFX HD</label>
                </p>
                <p>
                  <input class="is-checkradio" id="previousDrugsCYCLO" type="checkbox" v-model="PreviousDrugs.CYCLO" name="previousDrugsCYCLO">
                  <label for="previousDrugsCYCLO">CYCLO</label>
                </p>
                <p>
                  <input class="is-checkradio" id="previousDrugsPAS" type="checkbox" v-model="PreviousDrugs.PAS" name="previousDrugsPAS">
                  <label for="previousDrugsPAS">PAS</label>
                </p>
                <p>
                  <input class="is-checkradio" id="previousDrugsLZD" type="checkbox" v-model="PreviousDrugs.LZD" name="previousDrugsLZD">
                  <label for="previousDrugsLZD">LZD</label>
                </p>
                               <p>
                  <input class="is-checkradio" id="previousDrugsCFZ" type="checkbox" v-model="PreviousDrugs.CFZ" name="previousDrugsCFZ">
                  <label for="previousDrugsCFZ">CFZ</label>
                </p>
                <p>
                  <input class="is-checkradio" id="previousDrugsINHHD" type="checkbox" v-model="PreviousDrugs.INHHD" name="previousDrugsINHHD">
                  <label for="previousDrugsINHHD">INH HD</label>
                </p>
              </div>
              <div class="field has-text-left">
                <p>
                  <input class="is-checkradio" id="previousDrugsETO" type="checkbox" v-model="PreviousDrugs.ETO" name="previousDrugsETO">
                  <label for="previousDrugsETO">ETO</label>
                </p>
                <p>
                  <input class="is-checkradio" id="previousDrugsDLM" type="checkbox" v-model="PreviousDrugs.DLM" name="previousDrugsDLM">
                  <label for="previousDrugsDLM">DLM</label>
                </p>
                <p>
                  <input class="is-checkradio" id="previousDrugsDBQ" type="checkbox" v-model="PreviousDrugs.DBQ" name="previousDrugsDBQ">
                  <label for="previousDrugsDBQ">DBQ</label>
                </p>
                <p>
                  <input class="is-checkradio" id="previousDrugsCarbapenems" type="checkbox" v-model="PreviousDrugs.Carbapenems" name="previousDrugsCarbapenems">
                  <label for="previousDrugsCarbapenems">Carbapenems</label>
                </p>
                <p v-if="PreviousDrugs.Carbapenems">
                  <label for="previousDrugsMFX">specify</label>
                  <input class="is-checkradio" id="previousDrugsCarbapenemsSpec" type="input" v-model="PreviousDrugs.CarbapenemsSpec" name="previousDrugsCarbapenemsSpec">
                </p>
              </div>

            </div>
          </div> 
          <div class="field is-horizontal" v-if="previousTBTerapy== 'Yes'">
            <div class="field-label is-normal">
                <label class="label">Outcome:</label>
            </div>
            <div class="field-body">
              <div class="field">
                <input class="is-checkradio" id="previousTB_outcome1" type="radio" value="Cured" v-model="Outcome" name="previousTB_outcome">
                <label for="previousTB_outcome1">Cured</label>
              </div>
              <div class="field">
                <input class="is-checkradio" id="previousTB_outcome2" type="radio" value="Tr. Completed" v-model="Outcome" name="previousTB_outcome">
                <label for="previousTB_outcome2">Tr. Completed</label>
              </div>
              <div class="field">
                <input class="is-checkradio" id="previousTB_outcome3" type="radio" value="Failure" v-model="Outcome" name="previousTB_outcome">
                <label for="previousTB_outcome3">Failure</label>
              </div>
              <div class="field">
                <input class="is-checkradio" id="previousTB_outcome5" type="radio" value="Lost to follow up" v-model="Outcome" name="previousTB_outcome">
                <label for="previousTB_outcome5">Lost to follow up</label>
              </div>
              <div class="field">
                <input class="is-checkradio" id="previousTB_outcome4" type="radio" value="Unknown" v-model="Outcome" name="previousTB_outcome">
                <label for="previousTB_outcome4">Unknown</label>
              </div>
            </div>
          </div>
     </div>
        <div class="box">
        <div class="field is-horizontal">
        <div class="field-label is-normal">
            <label class="label">Date of Current Episode:</label>
        </div>
        <div class="field-body">
            <div class="field">
            <div class="control">
              <v-date-picker 
                  id="btnSubmitDate" 
                  title-position="left" 
                  v-model="currTBE.dateEpisode"   
                  :popover="{ visibility: 'click', placement: 'bottom' }"
                  >
                    <template v-slot:default="{ inputValue, togglePopover }">
                    <button  class="button is-primary"                       
                    @click="togglePopover"
                    >
                    <i class="fas fa-calendar-check " style="margin-right:8px;"></i>{{currTBE.dateEpisode.toLocaleDateString()}}
                    </button>
                  </template>
                </v-date-picker>

            </div>
            </div>
            <div class="field">
                Duration of symptoms till diagnosis (n. of weeks):<br>
                
                <div class="select">
                <select name="" id="input" class="form-control" v-model="selectSymptomsTillDiagnosis" >
                    <option v-for="n in getNumbers(1,10)" :value="n"  v-bind:key="n">{{n}}</option>
                </select>
                </div>
            </div>
        </div>
        </div>

        <div class="field is-horizontal">
        <div class="field-label is-normal">
            <label class="label">Form :</label>
        </div>
        <div class="field-body">
            <div class="field">
            <input class="is-checkradio" id="currTBEForm1" type="radio" value="New Case" v-model="currTBE.Form" name="currTBEForm">
            <label for="currTBEForm1">New Case </label>
            </div>
            <div class="field">
            <input class="is-checkradio" id="currTBEForm2" type="radio" value="Failure" v-model="currTBE.Form" name="currTBEForm">
            <label for="currTBEForm2">Failure</label>
            </div>
            <div class="field">
            <input class="is-checkradio" id="currTBEForm3" type="radio" value="Relapse" v-model="currTBE.Form" name="currTBEForm">
            <label for="currTBEForm3">Relapse</label>
            </div>
            <div class="field">
            <input class="is-checkradio" id="currTBEForm4" type="radio" value="Unknown" v-model="currTBE.Form" name="currTBEForm">
            <label for="currTBEForm4">Unknown</label>
            </div>
        </div>
        </div>

        <div class="field is-horizontal">
        <div class="field-label is-normal">
            <label class="label">Type :</label>
        </div>


        <div class="field-body">
            <div class="field">
            <input class="is-checkradio" id="currentTBTypePulmonary" type="checkbox" v-model="currTBE.TypePulmonary" name="currentTBTypePulmonary">
            <label for="currentTBTypePulmonary">Pulmonary</label>
            </div>
            <div class="field">
            <input class="is-checkradio" id="currentTBTypeExtraPulmonary" type="checkbox" v-model="currTBE.TypeExtraPulmonary" name="currentTBTypeExtraPulmonary">
            <label for="currentTBTypeExtraPulmonary">Extra Pulmonary</label>
            </div>
            <div class="field">
            <label v-if="currTBE.TypeExtraPulmonary" for="currentTBTypeExtraPulmonarySpec">please specify:</label>
            <input v-if="currTBE.TypeExtraPulmonary" class="input" id="currentTBTypeExtraPulmonarySpec" v-model="currTBE.TypeExtraSpec" name="currentTBTypeExtraPulmonarySpec" > 
            </div>
        </div>
        </div>

        <div class="field is-horizontal">
        <div class="field-label is-normal">
            <label class="label"> Resistance Status:</label>
        </div>
        <div class="field-body">
            <div class="field has-text-left">
            <p>
                <input class="is-checkradio" id="currTBResistance1" type="radio" value="Susceptible" v-model="currTBE.Resistance" name="previousTBResistance">
                <label for="currTBResistance1">MDR-TB</label>
            </p>
            <p>
                <input class="is-checkradio" id="currTBResistance2" type="radio" value="Mono-resistant" v-model="currTBE.Resistance" name="previousTBResistance">
                <label for="currTBResistance2">MDR-TB Treatment Intolerant</label>
            </p>
            <p>
                <input class="is-checkradio" id="currTBResistance3" type="radio" value="Poly-resistant" v-model="currTBE.Resistance" name="previousTBResistance">
                <label for="currTBResistance3">MDR-TB Non Responsive</label>
            </p>
            </div>
            <div class="field has-text-left">
            <p>
                <input class="is-checkradio" id="currTBResistance4" type="radio" value="pre-XDR-TB" v-model="currTBE.Resistance" name="previousTBResistance">
                <label for="currTBResistance4">pre-XDR-TB</label>
            </p>
            <p>
                <input class="is-checkradio" id="currTBResistance5" type="radio" value="XDR-TB" v-model="currTBE.Resistance" name="previousTBResistance">
                <label for="currTBResistance5">XDR-TB</label>
            </p>
            </div>
        </div>
        </div>
        <div class="field is-horizontal">
        <div class="field-label is-normal">
            <label class="label"></label>
        </div>
        <div class="field-body">
            <div class="field has-text-left">
            <textarea class="textarea" v-model="currentTBComment" placeholder="Comments"></textarea>
            </div>
        </div>
        </div>
        <div class="field is-horizontal">
        <div class="field-label is-normal">
            <label class="label">TB extension:</label>
        </div>
        <div class="field-body">
            <div class="field has-text-left">
            <textarea class="textarea" v-model="TBExtension" placeholder="Example: Bilateral pulmonary TB with cavities in right upper lobe"></textarea>
            </div>
        </div>
        </div>


    </div>



    </div>
</template>

<script>
export default {
    name: 'InfoTBHistory',
    data(){ 
        return {
        previousTB:'No',
        previousTBTypePulmonary:'',
        previousTBTypeExtraPulmonary:'',
        previousTBTypeExtraSpec:'',
        previousTBResistance:'',
        previousTBTerapy:'',
        selectTherapyDay :0,
        PreviousDrugs:{
            INH : false,
            RMPO: false,
            ETB: false,
            Strepto: false,
            PZA: false,
            AMK: false,
            KANA: false,
            CAPREO:false,
            OFX:false,
            LFX: false,
            LFXHD: false,
            MFX: false,
            MFXHD: false,
            CYCLO: false,
            PAS: false,
            LZD: false,
            CFZ: false,
            INH_HD: false,
            ETO: false,
            DLM: false,
            BDQ: false,
            Carbapenems: false,
            CarbapenemsSpec:'',

        },
        Outcome:'',
        currTBE:{
            dateEpisode: new Date(),
            Form:'',
            TypePulmonary:'',
            TypeExtraPulmonary:'',
            TypeSpec:'',
            Resistance:'',
            ResistanceSpec:'',

          },
          currentTBComment:'',
          TBExtension:'',
          selectSymptomsTillDiagnosis: 0
        }        
        
    },
    methods:{
      getReverseNumbers:function(start,stop){
        return new Array(start-stop).fill(start).map((n,i)=>n-i);
      },
      getNumbers:function(start,stop){
        return new Array(stop-start).fill(start).map((n,i)=>n+i);
      }
    }

}
</script>