<template>
  <div>
    <div class="box" style="width:450px;margin:auto;margin-top:10.0em;">
      <form @submit.prevent="login" autocomplete="off">
          <img width="100%" height="100%" style="width:100%; height:100%;" src="~@/assets/consilium_logo.png" />
          <div class="field">
            <label class="label is-large">Email Address</label>
            <div class="control has-icons-left has-icons-right">
              <input class="input is-large" type="email" v-model="email" v-focus placeholder="Enter Your Email" value="hello@">
              <span class="icon is-large is-left">
                  <i class="fas fa-envelope"></i>
              </span>
            </div>
          </div>

          <div class="field">
            <label class="label is-large">Password</label>
            <div class="control has-icons-left has-icons-right">
              <input class="input is-large" type="password" v-model="pass"  placeholder="Enter Your Password" value="hello@">
              <span class="icon is-large is-left">
                  <i class="fas fa-unlock-alt"></i>
              </span>
            </div>
          </div>
          <div class="field" style="text-align:center;">
              <button class="button is-primary is-large" :disabled="loading" type="submit">
                <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                <span>Login</span>

              </button>
          </div>

          
      <p v-if="error" class="error">Bad login information</p>
      </form>    


    </div>    
    <div  style="text-align:center;margin-top:15px;">
        <h4 class="subtitle is-4">or <router-link to="/">Sign up</router-link></h4>

    </div>
  </div>

</template>

<script>
  export default {
    data () {
      return {
        email: '',
        message: '',
        pass: '',
        error: false,
        environment: process.env.VUE_APP_ENVIRONMENT,
        nameApp: process.env.VUE_APP_TITLE
      }
    },
    computed: {
    loading() {
      return this.$store.state.auth.status.loading;
    }
  },
  created() {
    if (this.$store.getters['auth/isLogged']) {
      this.$router.push('/summary');
    }
  },
    methods: {
      async login () {
        this.message = '';
    console.log('Starting login attempt...'); // Debug log
    try {
      await this.$store.dispatch('auth/login', {
        email: this.email,
        password: this.pass
      });
      window.location.href = '/summary';
    } catch (error) {
      console.error('Login error:', error); // Debug log
      this.message = error.message;
    }
      }
    }
  }
</script>

<style>
  .error {
    color: red;
  }
</style>