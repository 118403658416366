<template>
    <div class="box">
        <div class="columns is-centered">
            <div class="column is-half">
                Date Start:<br>
                <v-date-picker 
                  id="btnDateStart" 
                  title-position="left" 
                  v-model="startDate"   
                  :popover="{ visibility: 'click', placement: 'bottom' }"
                  >
                    <template v-slot:default="{ inputValue, togglePopover }">
                    <button  class="button is-primary"                       
                    @click="togglePopover"
                    >
                    <i class="fas fa-calendar-check " style="margin-right:8px;"></i>{{startDate.toLocaleDateString()}}
                    </button>
                  </template>
                </v-date-picker>


            </div>
            <div class="column is-half">
                Date End:<br>
                <v-date-picker 
                  id="btnDateSEnd" 
                  title-position="left" 
                  v-model="endDate"   
                  :popover="{ visibility: 'click', placement: 'bottom' }"
                  >
                    <template v-slot:default="{ inputValue, togglePopover }">
                    <button  class="button is-primary"                       
                    @click="togglePopover"
                    >
                    <i class="fas fa-calendar-check " style="margin-right:8px;"></i>{{endDate.toLocaleDateString()}}
                    </button>
                  </template>
                </v-date-picker>

            </div>
        </div>

        <div class=" columns is-multiline is-centered">
            <div class="column is-one-quarter">
                <div class="column">
                    Drug:<br>
                    <div class="select">
                    <select v-model="selectedDrug" class="form-control">
                        <option v-for="drug in drugs" :value="drug" v-bind:key="drug">{{drug}}</option>

                    </select>
                    </div>
                </div>
                <div class="column" v-if="selectedDrug=='Other'">
                    <input class="input "  type="text" v-model="otherDrug" />
                </div>
            </div>
            <div class="column is-one-quarter">
                <div class="column">
                Dose Prescribed (mg):<br>
                <input class="input "  type="text" v-model="dosePrescribed"  @keypress="isNumber($event)" />
                </div>
            </div>
            <div class="column is-one-quarter">
                <div class="column">
                    Drug Interruption:<br/>
                <input class="is-checkradio" id="DrugInterruptionYes" type="radio" value="Yes" v-model="drugInterruption" name="DrugInterruption">
                <label for="DrugInterruptionYes">Yes</label>
                <input class="is-checkradio" id="DrugInterruptionNo" type="radio" value="No" v-model="drugInterruption" name="DrugInterruption">
                <label for="DrugInterruptionNo">No</label>
                </div>
                <div class="column" v-if="drugInterruption=='Yes'">
                    Days of Interruption:<br>
                    <input class="input"  type="text" v-model="daysOfInterruption"  @keypress="isNumber($event)" />
                </div>
                <div class="column" v-if="drugInterruption=='Yes'">
                    Reason of Interruption:<br>
                    <input class="input"  type="text" v-model="reasonInterruption"  />
                </div>
            </div>

            <div class="column is-one-quarter">
                <label class="label">Total Drugs Exposure (days):</label>
                <label class="input" id="BMI" type="text" name="BMI">{{totalDrugExposure}}</label>
    
            </div>
        </div>
        
        <div class=" field is-horizontal">          
            <div class=" field-body">
            <div class="field" style="text-align:center;">
                <button class="button is-primary is-normal" v-on:click="addTreatment" >Add administered drug</button>
            </div>
            </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-body">
            <div class="field">
              <table class="table is-striped" style="width:100%;">
                <thead>
                  <tr>
                    <th>Drug</th>
                    <th>Date Start</th>
                    <th>Dose Prescribed<br/>(mg)</th>
                    <th>Drug Interruption</th>
                    <th>Days of Interruption</th>
                    <th>Reason of Interruption</th>
                    <th>Date End</th>
                    <th>Total Drug Exposure <br/> (days)</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="entry in treatments" v-bind:key="entry">
                    <td >
                      {{entry.drug}}
                    </td>
                    <td >
                      {{entry.startDate}}
                    </td>
                    <td >
                      {{entry.dosePrescribed}}
                    </td>
                    <td >
                      {{entry.drugInterruption}}
                    </td>
                    <td >
                      {{entry.daysOfInterruption}}
                    </td>
                    <td >
                      {{entry.reasonInterruption}}
                    </td>
                    <td >
                      {{entry.endDate}}
                    </td>
                    <td >
                      {{entry.totalDrugExposure}}
                    </td>
                    <td >
                       <button class="delete" v-on:click="deletRegimen(entry)"></button>
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="box">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Further comments:</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <textarea class="textarea" placeholder="Enter your comment(s) here." v-model="furtherCommet"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>


    </div>
</template>

<script>
export default {
    name: 'TreatmentRegimenCurrent',
    data() {
        return{
            furtherCommet:'',
            processing: false,
            treatments:[],
            selectedDrug:'',
            otherDrug:'',
            startDate: new Date(),
            endDate: new Date(),
            dosePrescribed: 0,
            drugInterruption: 'No',
            daysOfInterruption:0,
            reasonInterruption:'',
            totalDrugExposure:0,
            drugs:[
                'Bedaquiline',
                'Levofloxacin',
                'High Dose Moxifloxacin',
                'Linezolid',
                'Cycloserine',
                'Terizidone',
                'Clofazimine',
                'Ethionamide',
                'Prothionamide',
                'Ethambutol',
                'Pyrazinamide',
                'PAS',
                'Delamanid',
                'Kanamycin',
                'Capreomycin',
                'Amikacin',
                'Meropenem',
                'Amoxicillin/clavulanic acid',
                'Other'
            ]

        }
    },
    watch:{
      startDate:function(val){
        this.totalDrugExposure = Math.round((this.endDate -val)/ (1000 * 3600 * 24));
      },
      endDate:function(val){
        this.totalDrugExposure = Math.round((val - this.startDate)/ (1000 * 3600 * 24));
      }
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        deletRegimen(regimen){
          this.treatments.splice(this.treatments.indexOf(regimen),1);
        },
        addTreatment(){

            if (this.processing === true) {
                return;
            } 
            this.processing = true;
            const niceDateStart = this.startDate.toLocaleDateString();
            const niceDeteEnd = this.endDate.toLocaleDateString();
            let completeDrug;
            if (this.selectedDrug=='Other'){
                completeDrug = this.selectedDrug + ' - ' + this.otherDrug;
            } else {
                completeDrug = this.selectedDrug;
            }
            this.treatments.push({
                startDate:niceDateStart,
                endDate: niceDeteEnd,
                drug: completeDrug,
                drugOther:this.otherDrug,
                dosePrescribed: this.dosePrescribed,
                drugInterruption: this.drugInterruption,
                daysOfInterruption: this.daysOfInterruption,
                reasonInterruption: this.reasonInterruption,
                totalDrugExposure: this.totalDrugExposure,
            });
            this.selectedDrug = '',
            this.otherDrug = '',
            this.dateStart = new Date(),
            this.endDate = new Date(),
            this.dosePrescribed = 0,
            this.drugInterruption = '',
            this.daysOfInterruption = 0,
            this.reasonInterruption = '',
            this.totalDrugExposure = 0,

            setTimeout(() => {
                this.processing = false;
            }, 1000);


        }
    }
}
</script>