import AuthService from '../services/auth.service';

const initialState = {
  status: {
    loggedIn: AuthService.isLogged(),
    loading: false,
    error: null
  },
  user: AuthService.getCurrentUser()
};

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    async login({ commit }, { email, password }) {
      console.log('Login action started'); // Debug log
      commit('loginRequest');
      try {
        console.log('Calling AuthService.login...'); // Debug log
        const userData = await AuthService.login(email, password);
        console.log('AuthService.login response:', userData); // Debug log
        commit('loginSuccess', userData);
        return Promise.resolve(userData);
      } catch (error) {
        console.error('Login action error:', error); // Debug log
        commit('loginFailure', error.message);
        return Promise.reject(error);
      }
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    }
  },
  mutations: {
    loginRequest(state) {
      state.status = { loading: true };
    },
    loginSuccess(state, userData) {
      state.status = { loggedIn: true };
      state.user = userData.user;
    },
    loginFailure(state, error) {
      state.status = { error };
      state.user = null;
    },
    logout(state) {
      state.status = { loggedIn: false };
      state.user = null;
    }
  },
  getters: {
    isLogged: state => state.status.loggedIn,
    currentUser: state => state.user,
    authStatus: state => state.status
  }
};