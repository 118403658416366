<template>
  <div>
 <div v-if="errorCauses.length >0" class="notification is-danger is-light" style="margin-top:15px;">
      <p v-for="entry in errorCauses" v-bind:key="entry">
        {{entry['errorSummary']}}
      </p>
    </div>
    <div class="box" style="width:450px;margin:auto;margin-top:1.0em;">
        <div style="display: flex;justify-content: center;margin-top5px;">
          <img width="100%" height="100%" style="width:100%; height:100%;" src="~@/assets/consilium_logo.png" />
        </div>
        <div style="display: flex;justify-content: center;" >
        
        <h4 class="title is-3 is-spaced" style="color:#00d1b2;">
            Create your account
        </h4>
        </div>
        <form @submit.prevent="createuser" autocomplete="off" style="margin-top:30px;">

            <div class="field">
            <label class="label is-normal">Title </label>
            <div class="control has-icons-left has-icons-right">
              
              <div class="select is-normal">
                <select name="title" v-model="title" v-focus>
                  <option value="Mr">Mr.</option>
                  <option value="Mrs">Mrs. </option>
                  <option value="M.D.">M.D.</option>
                </select>
              </div>
              <span class="icon is-normal is-left">
                  <i class="fas fa-address-card"></i>
              </span>
            </div>
            </div>


            <div class="field">
            <label class="label is-normal">First Name </label>
            <div class="control has-icons-left has-icons-right">
              <input class="input is-normal" type="text" v-model="firstName" v-focus placeholder="Enter your name " value="hello@">
              <span class="icon is-normal is-left">
                  <i class="fas fa-user"></i>
              </span>
            </div>
            </div>


            <div class="field">
            <label class="label is-normal">Last Name</label>
            <div class="control has-icons-left has-icons-right">
              <input class="input is-normal" type="text" v-model="lastName" v-focus placeholder="Enter your surname" value="hello@">
              <span class="icon is-normal is-left">
                  <i class="fas fa-user"></i>
              </span>
            </div>
            </div>

            <div class="field">
            <label class="label is-normal">Email </label>
            <div class="control has-icons-left has-icons-right">
              <input class="input is-normal" type="email" v-model="email" v-focus placeholder="youremail@domain.com" value="hello@">
              <span class="icon is-normal is-left">
                  <i class="fas fa-envelope"></i>
              </span>
            </div>

            <div class="field">
            <label class="label is-normal">Password </label>
            <div class="control has-icons-left has-icons-right">
              <input class="input is-normal" type="password" v-model="password" v-focus placeholder="your own password" value="Not pippo 123@">
              <span class="icon is-normal is-left">
                  <i class="fas fa-unlock-alt"></i>
              </span>
            </div>
            </div>
          </div>

            <div class="field" style="text-align:center;">
              <button class="button is-primary is-normal" type="submit">Register</button>
          </div>
        </form>
    </div>
    <div  style="text-align:center;margin-top:15px;">
        <h4 class="subtitle is-5">Already have an account? <router-link to="/login">Sign in</router-link></h4>

    </div>
   



  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    data () {
      return {
        title:'',
        firstName: '',
        lastName: '',
        email: '',
        password:'',
        error: false,
        errorCauses:[]
      }
    },
    methods:{
      createuser(){
        this.postUser();
      },
      async postUser(){
        const datafom = {
          email: this.email,
          firstName: this.firstName,
          lastName:this.lastName,
          title: this.title,
          password: this.password
        }
            axios.post(process.env.VUE_APP_API_SERVICE + '/newuser', datafom, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
          .then(response => {
            console.log(response)
            console.log(response.data)
            const responseJson =(response.data) 
            console.log(responseJson.message)
            if (responseJson.errorCode){
              this.errorCauses =responseJson.errorCauses;
            } else {
              this.$router.push({path: 'login'})
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch(error => {
              // handle authentication and validation errors here
              this.errorCauses.push({"errorSummary":"Generic Error"});
          })
      }
    }
  }
</script>