// auth.service.js
import apiClient from '../api/axios';

const TOKEN_KEY = 'auth_token';
const USER_KEY = 'auth_user';

class AuthService {
  async login(email, password) {
    console.log(process.env.VUE_APP_API_SERVICE + '/login');
    try {
      const response = await apiClient.post('/login', {
        username: email,
        password: password
      });
      
      // Store the token and user data
      if (response.data.token) {
        localStorage.setItem(TOKEN_KEY, response.data.token);
        localStorage.setItem(USER_KEY, JSON.stringify(response.data.user));
      }
      
      return response.data;
    } catch (error) {
      console.error('Login error:', error.response?.data || error.message);
      throw error;
    }
  }

  isLogged() {
    const token = this.getToken();
    const user = this.getCurrentUser();
    return !!(token && user);
  }

  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  }

  getCurrentUser() {
    const userStr = localStorage.getItem(USER_KEY);
    if (!userStr) return null;
    return JSON.parse(userStr);
  }

  logout() {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
  }

  async register(email, password, title, firstName, lastName) {
    const response = await apiClient.post('/register', {
      title,
      email,
      password,
      firstName,
      lastName
    });
    return response.data;
  }

  getName() {
    const user = this.getCurrentUser();
    console.log(user)
    return user ? `${user.name}` : '';
  }

  getEmail() {
    const user = this.getCurrentUser();
    return user ? user.email : '';
  }

  getSub() {
    const user = this.getCurrentUser();
    return user ? user.id : '';
  }
}

export default new AuthService();