<template>
    <div class="box">
        <div class="field is-horizontal" style="margin-top:1.5em;">
            <div class="field-label is-normal">
                <label class="label">Adverse event(s)</label>
            </div>
            <div class="field-body">
                <div id="status" class="field-body">
                <div class="label is-normal">
                </div>

                <div class="field">
                    <input class="is-checkradio" id="adverseEventYes" type="radio" value="Yes" v-model="adverseEvent" name="adverseEventYes">
                    <label for="adverseEventYes">Yes</label>
                    <input class="is-checkradio" id="adverseEventNo" type="radio" value="No" v-model="adverseEvent" name="adverseEventNo">
                    <label for="adverseEventNo">No</label>
                    <input class="is-checkradio" id="adverseEventUnknown" type="radio" value="Unknown" v-model="adverseEvent" name="adverseEventUnknown">
                    <label for="adverseEventUnknown">Unknown</label>
                </div>
                </div>
            </div>
        </div>
        <div class="columns" v-if="adverseEvent=='Yes'">
            <div class="column is-one-third">
                Description <br/>
                <div class="control">
                  <textarea class="textarea" placeholder="Adverse Event Description." v-model="eventDescription"></textarea>
                </div>
            </div>
            <div class="column">
                Drug(s) responsible <br/>
                <div class="control">
                  <textarea class="textarea" placeholder="List of drugs responsible of the event." v-model="drugsResponsible"></textarea>
                </div>
            </div>
            <div class="column ">
                Grading AE: <br/>
                <div class="select">
                <select v-model="selectedgradingAEValue" class="form-control">
                    <option v-for="gradingAEValue in gradingAEValues" :value="gradingAEValue" v-bind:key="gradingAEValue">{{gradingAEValue}}</option>

                </select>
                </div>
            </div>
            <div class="column ">
                Action Taken: <br/>
                <div class="select">
                <select v-model="selectedActionTaken" class="form-control">
                    <option v-for="actionTakenValue in actionTakenValues" :value="actionTakenValue" v-bind:key="actionTakenValue">{{actionTakenValue}}</option>
                </select>
                </div>
            </div>
        </div>
        <div class=" field is-horizontal" v-if="adverseEvent=='Yes'">          
            <div class=" field-body">
            <div class="field" style="text-align:center;">
                <button class="button is-primary is-normal" v-on:click="addAdverseEvent" >Add Adverse Event</button>
            </div>
            </div>
        </div>

        <div class="field is-horizontal" v-if="adverseEvent=='Yes'">
          <div class="field-body">
            <div class="field">
              <table class="table is-striped" style="width:100%;">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Drug(s)<br>Responsible</th>
                    <th>Grading AE</th>
                    <th>Action Taken</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="entry in adverseEvents" v-bind:key="entry" >
                    <td>
                      <span v-html="entry.eventDescription"></span>
                    </td>
                    <td>
                      <span v-html="entry.drugsResponsible"></span>
                    </td>
                    <td >
                      {{entry.selectedgradingAEValue}}
                    </td>
                    <td >
                      {{entry.selectedActionTaken}}
                    </td>
                    <td >
                       <button class="delete" v-on:click="deleteEvent(entry)"></button>
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AdverseEvent',
    data() {
        return{
            gradingAEValues:[
                'Grade 1 (Mild)',
                'Grade 2 (Moderate)',
                'Grade 3 (Severe)',
                'Grade 4 (potentially Life-threating)'
            ],
            actionTakenValues:[
                'Drug Interruption',
                'Dose Increased',
                'Dose Reduced',
                'Dose Not Changed',
                'Unknown'
            ],
            selectedgradingAEValue:'',
            selectedActionTaken: '',
            adverseEvent:'No',
            drugsResponsible: '',
            eventDescription:'',
            adverseEvents:[],
            processing:false
        }
    },
    methods:{
        deleteEvent(event){
          this.adverseEvents.splice(this.adverseEvents.indexOf(event),1);
        },
 
        addAdverseEvent(){
                      if (this.processing === true) {
                return;
            } 
            this.processing = true;
            this.adverseEvents.push({
                eventDescription: this.eventDescription.replace(/(?:\r\n|\r|\n)/g, '<br>'),
                drugsResponsible: this.drugsResponsible.replace(/(?:\r\n|\r|\n)/g, '<br>'),
                selectedgradingAEValue: this.selectedgradingAEValue,
                selectedActionTaken: this.selectedActionTaken
            });
            this.eventDescription = '';
            this.drugsResponsible = '';
            this.selectedgradingAEValue = '';
            this.selectedActionTaken = '',
            setTimeout(() => {
                this.processing = false;
            }, 1000);
        }

    }

}
</script>