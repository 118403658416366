<template>
<div>
  <DIV style="margin-bottom:3.0em;">
    <section class="hero is-primary">
        <div class="hero-body">
          <div class="container">
            <h1 class="title">
              Global TB Consilium -- Confidential <br/> Patient Form
            </h1>
            <h2 class="subtitle">
              This form should be fully completed by the treating physician!
            </h2>
          </div>
        </div>
      </section>
  </DIV>
  <div class="steps" id="stepsDemo" >
    


    <div class="step-item is-active is-primary">
      <div class="step-marker">1</div>
      <div class="step-details">
        <p class="step-title">General</p>
      </div>
    </div>
    <div class="step-item">
      <div class="step-marker">2</div>
      <div class="step-details">
        <p class="step-title">Information on TB history</p>
      </div>
    </div>
    <div class="step-item">
      <div class="step-marker">3</div>
      <div class="step-details">
        <p class="step-title">Bacteriological Examination</p>
      </div>
    </div>
      <div class="step-item">
      <div class="step-marker">4</div>
      <div class="step-details">
        <p class="step-title">DST Results</p>
      </div>
    </div>
    <div class="step-item">
      <div class="step-marker">5</div>
      <div class="step-details">
        <p class="step-title">Molecular Tests</p>
      </div>
    </div>
    <div class="step-item">
      <div class="step-marker">6</div>
      <div class="step-details">
        <p class="step-title">Treatment regimen</p>
      </div>
    </div>
    <div class="step-item">
      <div class="step-marker">7</div>
      <div class="step-details">
        <p class="step-title">Adverse Events</p>
      </div>
    </div>
    <div class="step-item">
      <div class="step-marker">8</div>
      <div class="step-details">
        <p class="step-title">Therapeutic Drug Monitoring</p>
      </div>
    </div>
    <div class="step-item">
      <div class="step-marker">
             <span class="icon">
        <i class="fa fa-flag"></i>
      </span>
      </div>
      <div class="step-details">
        <p class="step-title">Submission</p>
      </div>
    </div>
   
    <div v-if="currentError !== ''" class="notification is-danger has-text-centered" style="flex-basis: 100%;">
      {{currentError}}
    </div>

    <div v-if="currentLegend !== ''" class="notification is-primary has-text-centered" style="flex-basis: 100%;">
      {{currentLegend}}
    </div>


    <div class="steps-content ">

      <div class="box step-content has-text-centered is-active">
        <TBGeneralInformation ref="TBGeneralInformation" />
      </div> 

      <div class="box step-content has-text-centered">
        <InfoTBHistory ref="InfoTBHistory" />
      </div>

      <div class="box step-content has-text-centered">
        <div class="box">
          <div class="field is-horizontal">
            
            <div class=" field-body">
              <div class="field">
                <label class="label" for="NewBactDate">Sputum collection date</label>
                <v-date-picker 
                  id="btnDate" 
                  title-position="left" 
                  v-model="NewBactDate"   
                  :popover="{ visibility: 'click', placement: 'bottom' }"
                  >
                    <template v-slot:default="{ inputValue, togglePopover }">
                    <button  class="button is-primary"                       
                    @click="togglePopover"
                    >
                    <i class="fas fa-calendar-check " style="margin-right:8px;"></i>{{NewBactDate.toLocaleDateString()}}
                    </button>
                  </template>
                </v-date-picker>

              </div>          
              <div class="field">
                <label class="label" for="NewBactSmear">Smear result</label>
                <div class="select">
                  <select name="" id="input" class="form-control" v-model="NewBactSmear" >
                    <option value="Positive">Positive</option>
                    <option value="Negative">Negative</option>
                    <option value="Not Done">Not Done</option>
                  </select>
                </div>
              </div> 
              <div class="field">
                <label class="label" for="NewBactCulture">Culture result</label>
                <div class="select">
                  <select name="" id="input" class="form-control" v-model="NewBactCulture" >
                    <option value="Positive">Positive</option>
                    <option value="Negative">Negative</option>
                    <option value="Not Done">Not Done</option>
                  </select>
                </div>
              </div> 
              <div class="field">
                <label class="label" for="NewBactSCDate">Date of S/C Result</label>
                <v-date-picker 
                  id="btnDate" 
                  title-position="left" 
                  v-model="NewBactSCDate"   
                  :popover="{ visibility: 'click', placement: 'bottom' }"
                  >
                    <template v-slot:default="{ inputValue, togglePopover }">
                    <button  class="button is-primary"                       
                    @click="togglePopover"
                    >
                    <i class="fas fa-calendar-check " style="margin-right:8px;"></i>{{NewBactSCDate.toLocaleDateString()}}
                    </button>
                  </template>
                </v-date-picker>
              </div> 
            </div>
          </div>
          
          <div class=" field is-horizontal">          
            <div class=" field-body">
              <div class="field" style="text-align:center;">
                <button class="button is-primary is-normal" v-on:click="addExamination" >Add Examination</button>
              </div>
            </div>
          </div>

        </div>
        <div class="field is-horizontal">
          <div class="field-body">
            <div class="field">
              <table class="table is-striped" style="width:100%;">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Smear Result</th>
                    <th>Culture Result</th>
                    <th>Date of S/C Result</th>
                    <th></th>>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="entry in BacteriologicalExaminations" v-bind:key="entry">
                    <td >
                      {{entry.collectionDate}}
                    </td>
                    <td >
                      {{entry.smear}}
                    </td>
                    <td >
                      {{entry.culture}}
                    </td>
                    <td >
                      {{entry.SCResult}}
                    </td>
                    <td >
                       <button class="delete" v-on:click="deleteExamination(entry)"></button>
                    </td>
                  </tr>
                </tbody>


              </table>
            </div>
          </div>
        </div>
        
      </div>

      <div class="step-content has-text-centered">


       <div class="box">
          <div class="field is-horizontal">
             <div class="field-label is-normal">
                <label class="label" for="NewBactDate">Date DST</label>

                <v-date-picker 
                  id="btnDSTDate" 
                  title-position="left" 
                  v-model="DSTResult.date"   
                  :popover="{ visibility: 'click', placement: 'bottom' }"
                  >
                    <template v-slot:default="{ inputValue, togglePopover }">
                    <button  class="button is-primary"                       
                    @click="togglePopover"
                    >
                    <i class="fas fa-calendar-check " style="margin-right:8px;"></i>{{DSTResult.date.toLocaleDateString()}}
                    </button>
                  </template>
                </v-date-picker>
                
            </div>
            <div class=" field-body">
              <div class="field">
                <label class="label" for="DSTResultR">H</label>
                <div class="select">
                  <select name="" id="DSTResultH" class="form-control" v-model="DSTResult.H" >
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="ND">ND</option>
                    <option value="U">U</option>
                  </select>
                </div>
              </div> 
              <div class="field">
                <label class="label" for="DSTResultR">R</label>
                <div class="select">
                  <select name="" id="DSTResultR" class="form-control" v-model="DSTResult.R" >
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="ND">ND</option>
                    <option value="U">U</option>
                  </select>
                </div>
              </div> 
              <div class="field">
                <label class="label" for="DSTResultE">E</label>
                <div class="select">
                  <select name="" id="DSTResultE" class="form-control" v-model="DSTResult.E" >
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="ND">ND</option>
                    <option value="U">U</option>
                  </select>
                </div>
              </div>
              <div class="field">
                <label class="label" for="DSTResultS">S</label>
                <div class="select">
                  <select name="" id="DSTResultS" class="form-control" v-model="DSTResult.S" >
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="ND">ND</option>
                    <option value="U">U</option>
                  </select>
                </div>
              </div>

              <div class="field">
                <label class="label" for="DSTResultR">Z</label>
                <div class="select">
                  <select name="" id="DSTResultZ" class="form-control" v-model="DSTResult.Z" >
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="ND">ND</option>
                    <option value="U">U</option>
                  </select>
                </div>
              </div> 
              <div class="field">
                <label class="label" for="DSTResultR">Am</label>
                <div class="select">
                  <select name="" id="DSTResultAm" class="form-control" v-model="DSTResult.Am" >
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="ND">ND</option>
                    <option value="U">U</option>
                  </select>
                </div>
              </div> 
              <div class="field">
                <label class="label" for="DSTResultE">Km</label>
                <div class="select">
                  <select name="" id="DSTResultKm" class="form-control" v-model="DSTResult.Km" >
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="ND">ND</option>
                    <option value="U">U</option>
                  </select>
                </div>
              </div>
              <div class="field">
                <label class="label" for="DSTResultS">Cm</label>
                <div class="select">
                  <select name="" id="DSTResultCm" class="form-control" v-model="DSTResult.Cm" >
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="ND">ND</option>
                    <option value="U">U</option>
                  </select>
                </div>
              </div>

            </div>
          </div>
          <div class="field is-horizontal">
             <div class="field-label is-normal">
            </div>
            <div class=" field-body">
              <div class="field">
                <label class="label" for="DSTResultOfx">Ofx</label>
                <div class="select">
                  <select name="" id="DSTResultOfx" class="form-control" v-model="DSTResult.Ofx" >
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="ND">ND</option>
                    <option value="U">U</option>
                  </select>
                </div>
              </div> 
              <div class="field">
                <label class="label" for="DSTResultLfx">Lfx</label>
                <div class="select">
                  <select name="" id="DSTResultLfx" class="form-control" v-model="DSTResult.Lfx" >
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="ND">ND</option>
                    <option value="U">U</option>
                  </select>
                </div>
              </div> 
              <div class="field">
                <label class="label" for="DSTResultLfxHD">Lfx HD</label>
                <div class="select">
                  <select name="" id="DSTResultLfxHD" class="form-control" v-model="DSTResult.LfxHD" >
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="ND">ND</option>
                    <option value="U">U</option>
                  </select>
                </div>
              </div>
              <div class="field">
                <label class="label" for="DSTResultMfx">Mfx</label>
                <div class="select">
                  <select name="" id="DSTResultMfx" class="form-control" v-model="DSTResult.Mfx" >
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="ND">ND</option>
                    <option value="U">U</option>
                  </select>
                </div>
              </div>

              <div class="field">
                <label class="label" for="DSTResultMfxHD">Mfx HD</label>
                <div class="select">
                  <select name="" id="DSTResultMfxHD" class="form-control" v-model="DSTResult.MfxHD" >
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="ND">ND</option>
                    <option value="U">U</option>
                  </select>
                </div>
              </div> 
              <div class="field">
                <label class="label" for="DSTResultCs">Cs</label>
                <div class="select">
                  <select name="" id="DSTResultCs" class="form-control" v-model="DSTResult.Cs" >
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="ND">ND</option>
                    <option value="U">U</option>
                  </select>
                </div>
              </div> 
              <div class="field">
                <label class="label" for="DSTResultPAS">PAS</label>
                <div class="select">
                  <select name="" id="DSTResultPAS" class="form-control" v-model="DSTResult.PAS" >
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="ND">ND</option>
                    <option value="U">U</option>
                  </select>
                </div>
              </div>
              <div class="field">
                <label class="label" for="DSTResultLzd">Lzd</label>
                <div class="select">
                  <select name="" id="DSTResultLzd" class="form-control" v-model="DSTResult.Lzd" >
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="ND">ND</option>
                    <option value="U">U</option>
                  </select>
                </div>
              </div>

            </div>
          </div>
          

          <div class="field is-horizontal">
             <div class="field-label is-normal">
            </div>
            <div class=" field-body">
              <div class="field">
                <label class="label" for="DSTResultCfz">Cfz</label>
                <div class="select">
                  <select name="" id="DSTResultCfz" class="form-control" v-model="DSTResult.Cfz" >
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="ND">ND</option>
                    <option value="U">U</option>
                  </select>
                </div>
              </div> 
              <div class="field">
                <label class="label" for="DSTResultHHD">HHD</label>
                <div class="select">
                  <select name="" id="DSTResultHHD" class="form-control" v-model="DSTResult.HHD" >
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="ND">ND</option>
                    <option value="U">U</option>
                  </select>
                </div>
              </div> 
              <div class="field">
                <label class="label" for="DSTResultLfxEto">Eto</label>
                <div class="select">
                  <select name="" id="DSTResultEto" class="form-control" v-model="DSTResult.Eto" >
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="ND">ND</option>
                    <option value="U">U</option>
                  </select>
                </div>
              </div>
              <div class="field">
                <label class="label" for="DSTResultDlm">Dlm</label>
                <div class="select">
                  <select name="" id="DSTResultDlm" class="form-control" v-model="DSTResult.Dlm" >
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="ND">ND</option>
                    <option value="U">U</option>
                  </select>
                </div>
              </div>

              <div class="field">
                <label class="label" for="DSTResultBdq">Bdq</label>
                <div class="select">
                  <select name="" id="DSTResultBdq" class="form-control" v-model="DSTResult.Bdq" >
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="ND">ND</option>
                    <option value="U">U</option>
                  </select>
                </div>
              </div> 
              <div class="field">
                <label class="label" for="DSTResultCarbapenemsType">Carbapenems</label>
                <input class="input" id="DSTResultCarbapenemsType" name="DSTResultCarbapenemsType" v-model="DSTResult.CarbapenemsType">
 
              </div> 
              <div class="field">
                <label class="label" for="DSTResultCarbapenems">Carbapenems</label>
                <div class="select">
                  <select name="" id="DSTResultCarbapenems" class="form-control" v-model="DSTResult.Carbapenems" >
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="ND">ND</option>
                    <option value="U">U</option>
                  </select>
                </div>
              </div>
            </div>
          </div>


          <div class=" field is-horizontal">          
            <div class=" field-body">
              <div class="field" style="text-align:center;">
                <button class="button is-primary is-normal" v-on:click="addDST" >Add DST</button>
              </div>
            </div>
          </div>

        </div>
        <div class="field is-horizontal">
          <div class="field-body">
            <div class="field">
              <table class="table is-striped" style="width:100%;">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>H</th>
                    <th>R</th>
                    <th>E</th>
                    <th>S</th>
                    <th>Z</th>
                    <th>Am</th>
                    <th>Km</th>
                    <th>Cm</th>
                    <th>Ofx</th>
                    <th>Lfx</th>
                    <th>Lfx HD</th>
                    <th>Mfx</th>
                    <th>Mfx HD</th>
                    <th>Cs</th>
                    <th>PAS</th>
                    <th>Lzd</th>
                    <th>Cfz</th>
                    <th>H HD</th>
                    <th>Eto</th>
                    <th>Dlm</th>
                    <th>Bdq</th>
                    <th>Carbapenems</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="entry in DSTResults" v-bind:key="entry">
                    <td >
                      {{entry.date}}
                    </td>
                    <td >
                      {{entry.H}}
                    </td>
                    <td >
                      {{entry.R}}
                    </td>
                    <td >
                      {{entry.E}}
                    </td>
                    <td >
                      {{entry.S}}
                    </td>
                    <td >
                      {{entry.Z}}
                    </td>
                    <td >
                      {{entry.Am}}
                    </td>
                    <td >
                      {{entry.Km}}
                    </td>
                    <td >
                      {{entry.Cm}}
                    </td>
                    <td >
                      {{entry.Ofx}}
                    </td>
                    <td >
                      {{entry.Lfx}}
                    </td>
                    <td >
                      {{entry.LfxHD}}
                    </td>
                    <td >
                      {{entry.Mfx}}
                    </td>
                    <td >
                      {{entry.MfxHD}}
                    </td>
                    <td >
                      {{entry.Cs}}
                    </td>
                    <td >
                      {{entry.PAS}}
                    </td>
                    <td >
                      {{entry.Lzd}}
                    </td>
                    <td >
                      {{entry.Cfz}}
                    </td>
                    <td >
                      {{entry.HHD}}
                    </td>
                    <td >
                      {{entry.Eto}}
                    </td>
                    <td >
                      {{entry.Dlm}}
                    </td>
                    <td >
                      {{entry.Bdq}}
                    </td>
                    <td >
                      {{entry.CarbapenemsType}} - {{entry.Carbapenems}}
                    </td>
                    <td >
                      <button class="delete" v-on:click="deleteDST(entry)"></button>
                    </td>
                  </tr>
                </tbody>


              </table>
            </div>
          </div>
        </div>

        <div class="box">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Further comments:</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <textarea class="textarea" v-model="DSTComments" placeholder="Enter your comment(s) here."></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="step-content has-text-centered">
        <MolecularTest  ref="MolecularTestForm" />
      </div>

      <div class="step-content has-text-centered">
        <TreatmentRegimenCurrent ref="TreatmentRegimenCurrent" />
      </div>

      <div class="step-content has-text-centered">
        <AdversEvent ref="AdverseEventComponent" />
      </div>

      <div class="step-content has-text-centered">
        <TherapeuticMonitoring ref="TherapeuticMonitoringComponent" />
      </div>


      <!-- Symmary and Submission STEP -->
      <div class="step-content has-text-centered">

        <!--- Recap -->
        <div v-if="this.GI.physicianName" style="width:100%" class="box">
          <div class="columns">
              <div class="colum" style="float:left;"><p>Physician Name: <b> {{this.GI.physicianName}}</b></p></div>
          </div>
          <div class="columns">
          <div class="column">Date Case submitted: </div> 
          <div class="column">{{this.GI.submissionDate}}</div>
          </div>

          <hr/>

          <div class="columns">
          <div class="column"><b>Sex M/F:</b></div>  
          <div class="column"> {{this.GI.sex}}</div>
          </div>

          <div class="columns">
          <div class="column"><b>Year of Birth:</b></div>
          <div class="column">{{this.GI.yearOfBirth}}</div>
          </div>

          <div class="columns">
          <div class="column"><b>Age at Admission:</b></div> 
          <div class="column">{{this.GI.ageAtAdmission}}</div>
          </div>
            
          <div class="columns">
          <div class="column"><b>Country of Residence:</b></div> 
          <div class="column">{{this.GI.countryOfResidence}}</div>
          </div>

          <div class="columns">
          <div class="column"><b>Current Weight:</b></div> 
          <div class="column">{{this.GI.currentWeight}}</div>
          </div>

          <div class="columns">
          <div class="column"><b>Current Weight:</b></div>
          <div class="column">{{this.GI.heigth}}</div>
          </div>

          <div class="columns">
          <div class="column"><b>BMI:</b></div> 
          <div class="column">{{this.GI.BMI}}</div>
          </div>

          <div class="columns">
          <div class="column"><b>BCG Vaccinated:</b></div> 
          <div class="column">{{this.GI.BCGVaccinated}}</div>
          </div>

          <div class="columns">
          <div class="column"><b>Comorbidities Risk Factor:</b></div> 
          <div class="column" v-html="this.riskFactor" >{{this.riskFactor}}</div>
          </div>

          <div class="columns">
            <div class="column"><b>HIV Status:</b></div> 
            <div class="column">HIV Test Offered:  {{this.GI.hivStatus}}</div>
            <div class="column" v-if="this.GI.hivStatus == 'Yes'" >HIV Test:{{this.GI.hivTest}}</div>

            <div class="column" v-if="this.GI.hivTest == 'Positive'">
                AIDS Diagnosis:{{this.GI.aidsDiagnosis}}
                <label v-if="this.GI.aidsDiagnosis == 'Yes'" > and state year: {{this.GI.aidsDiagnosysYear}}</label>
                <label v-if="this.GI.aidsDiagnosis == 'Opportunistic Disease'" >  please state which: {{this.GI.aidsDiagnosisOpportunisticDeasease}}</label>
            </div>          
            <div class="column">LAST CD4 Count:{{this.GI.CD4Date}} - {{this.GI.CD4Result}}</div>
            <div class="column">HIV Viral Load:{{this.GI.HIVViralLoad}} 
              <label v-if="this.GI.HIVViralLoad == 'Detectable'" >({{this.GI.HIVViralLoadValue}}) </label>
                      
            </div>

          </div>
          <div v-if="this.GI.hivTest == 'Positive'">
          <div class="columns">
              <div class="column"><b>ART</b></div> 
          </div>
          <div class="columns">
              <div class="column">Date of initiation of the Current Regimen:</div> 
              <div class="column"> {{this.GI.ARTDateInit}}</div>
          </div>
          <div class="columns">
              <div class="column">Drugs and dosages:</div> 
              <div class="column">{{this.GI.ARTDrugsandDosage}}</div>
          </div>
          <div class="columns">
              <div class="column">Other Drugs:</div> 
              <div class="column">{{this.GI.ConcomitantMedication}}</div>
          </div> 
          </div>
        </div>
        <hr/>
        <div v-if="TBHistory.previousTB" style="width:100%" class="box">
          <div class="columns">
            <div class="column" style="float:left;"><p>Previous TB Episode: <b>{{TBHistory.previousTB}}</b></p></div>
          </div>


          <div v-if="TBHistory.previousTB == 'Yes'">
            <div class="columns" v-if="this.TBHistory.previousTBTypePulmonary">
                  <div class="column" style="float:left;">Type Pulmonary:</div> 
                  <div class="column"><b>YES</b></div>
            </div>
            <div class="columns" v-if="this.TBHistory.previousTBTypeExtraPulmonary">
                  <div class="column" style="float:left;">Type Extra Pulmonary:</div>
                  <div class="column"> <b>Yes  {{this.TBHistory.previousTBTypeExtraSpec}}</b></div>
            </div>
            <div class="columns" >
                  <div class="column" style="float:left;">Resistent Status:</div>
                  <div class="column"><b>{{this.TBHistory.previousTBResistance}}</b></div>
            </div>
            <div class="columns" >
                  <div class="column" style="float:left;">Prior anti-TB therapy >30 days:</div>
                  <div class="column"><b>{{this.TBHistory.previousTBTerapy}}</b></div>
            </div>
            <div class="columns" v-if="this.TBHistory.previousTBTerapy == 'Yes'" >
                  <div class="column" style="float:left;">Specify number of prior TB Therapies > 30 days:  </div>
                  <div class="column"><b>{{this.TBHistory.previousTBTherapyDay}}</b></div>
            </div>
            <div class="columns" v-if="this.TBHistory.previousTBTerapy == 'Yes'" >
                  <div class="column" style="float:left;">Specify anti-TB drugs of the last prior regimen:</div>
                  <div class="column" v-html="this.PreviousDrug" >{{this.PreviousDrug}}</div>
            </div>
            <div class="columns" v-if="this.TBHistory.previousTBTerapy == 'Yes'" >
                  <div class="column" style="float:left;">Outcome:</div>
                  <div class="column"><b>{{this.TBHistory.previousTBTherapyOutcome}}</b></div>
            </div>

          </div>
        </div>
        <hr/>
        <div style="width:100%" class="box">
            <div class="columns"  >
                  <div class="column" style="float:left;">Date of Current Episode:</div>
                  <div class="column"><b>{{this.TBHistory.currentTBDate}}</b></div>
            </div>
            <div class="columns"  >
                  <div class="column" style="float:left;">Duration of symptoms till diagnosis :</div>
                  <div class="column"><b>{{this.TBHistory.currentTBDurationSymptomsTillDiagnosis}}</b> weeks</div>
            </div>
            <div class="columns"  >
                  <div class="column" style="float:left;">Form :</div>
                  <div class="column"><b>{{this.TBHistory.currentTBForm}}</b></div>
            </div>
            <div class="columns" v-if="this.TBHistory.currentTBTypePulmonary">
                  <div class="column" style="float:left;">Type Pulmonary:</div> 
                  <div class="column"><b>YES</b></div>
            </div>
            <div class="columns" v-if="this.TBHistory.currentTBTypeExtraPulmonary">
                  <div class="column" style="float:left;">Type Extra Pulmonary:</div>
                  <div class="column"> <b>Yes  {{this.TBHistory.currentTBTypeExtraPulmonarySpec}}</b></div>
            </div>
            <div class="columns"  >
                  <div class="column" style="float:left;">Resistance Status :</div>
                  <div class="column"><b>{{this.TBHistory.currentTBTResistance}}</b></div>
            </div>
            <div class="columns"  >
                  <div class="column" style="float:left;"></div>
                  <div class="column"><b>{{this.TBHistory.currentTBComment}}</b></div>
            </div>
            <div class="columns"  >
                  <div class="column" style="float:left;">TB Extension</div>
                  <div class="column"><b>{{this.TBHistory.currentTBExtension}}</b></div>
            </div>
        </div>
        <hr/>
        <div class="box">
          <table class="table is-striped" style="width:100%;">
            <thead>
              <tr>
                <th>Date</th>
                <th>Smear Result</th>
                <th>Culture Result</th>
                <th>Date of S/C Result</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="entry in BacteriologicalExaminations" v-bind:key="entry">
                <td >
                  {{entry.collectionDate}}
                </td>
                <td >
                  {{entry.smear}}
                </td>
                <td >
                  {{entry.culture}}
                </td>
                <td >
                  {{entry.SCResult}}
                </td>
              </tr>
            </tbody>


          </table>
        </div>
        <hr/>

        <div class="box">
          <table class="table is-striped" style="width:100%;">
            <thead>
              <tr>
                <th>Date</th>
                <th>H</th>
                <th>R</th>
                <th>E</th>
                <th>S</th>
                <th>Z</th>
                <th>Am</th>
                <th>Km</th>
                <th>Cm</th>
                <th>Ofx</th>
                <th>Lfx</th>
                <th>Lfx HD</th>
                <th>Mfx</th>
                <th>Mfx HD</th>
                <th>Cs</th>
                <th>PAS</th>
                <th>Lzd</th>
                <th>Cfz</th>
                <th>H HD</th>
                <th>Eto</th>
                <th>Dlm</th>
                <th>Bdq</th>
                <th>Carbapenems</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="entry in DSTResults" v-bind:key="entry">
                <td >
                  {{entry.date}}
                </td>
                <td >
                  {{entry.H}}
                </td>
                <td >
                  {{entry.R}}
                </td>
                <td >
                  {{entry.E}}
                </td>
                <td >
                  {{entry.S}}
                </td>
                <td >
                  {{entry.Z}}
                </td>
                <td >
                  {{entry.Am}}
                </td>
                <td >
                  {{entry.Km}}
                </td>
                <td >
                  {{entry.Cm}}
                </td>
                <td >
                  {{entry.Ofx}}
                </td>
                <td >
                  {{entry.Lfx}}
                </td>
                <td >
                  {{entry.LfxHD}}
                </td>
                <td >
                  {{entry.Mfx}}
                </td>
                <td >
                  {{entry.MfxHD}}
                </td>
                <td >
                  {{entry.Cs}}
                </td>
                <td >
                  {{entry.PAS}}
                </td>
                <td >
                  {{entry.Lzd}}
                </td>
                <td >
                  {{entry.Cfz}}
                </td>
                <td >
                  {{entry.HHD}}
                </td>
                <td >
                  {{entry.Eto}}
                </td>
                <td >
                  {{entry.Dlm}}
                </td>
                <td >
                  {{entry.Bdq}}
                </td>
                <td >
                  {{entry.CarbapenemsType}} - {{entry.Carbapenems}}
                </td>
              </tr>
            </tbody>
          </table>

          <div class="columns">
            <div class="column">Further  Comments</div>
            <div class="column">{{DSTComments}}</div>
          </div>
        </div>
        <hr/>

        <div style="width:100%" class="box">
          <div class="columns">
            <div class="column">Xpert MTB/RIF</div>
            <div class="column"><b>{{MolecularTest.XpertMTBRIF}}</b></div>
          </div>
          <div class="columns" v-if="MolecularTest.XpertMTBRIF == 'Yes'">
            <div class="column"></div>
            <div class="column">
              <div class="columns">
                <div class="column">MTB</div>
                <div class="column"><b>{{MolecularTest.XpertMTBRIFMTB}}</b></div>
              </div>
              <div class="columns">
                <div class="column">Rif Resistant</div>
                <div class="column"><b>{{MolecularTest.XpertMTBRIFRifRes}}</b></div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">Xpert Ultra</div>
            <div class="column"><b>{{MolecularTest.XpertMTBRIFUltra}}</b></div>
          </div>
          <div class="columns" v-if="MolecularTest.XpertMTBRIFUltra == 'Yes'">
            <div class="column"></div>
            <div class="column">
              <div class="columns">
                <div class="column">MTB</div>
                <div class="column"><b>{{MolecularTest.XpertMTBRIFUltraMTB}}</b></div>
              </div>
              <div class="columns">
                <div class="column">Rif Resistant</div>
                <div class="column"><b>{{MolecularTest.XpertMTBRIFUltraRifRes}}</b></div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">Genotype MTBDRPlus</div>
            <div class="column"><b>{{MolecularTest.GenotypeMTBDRplus}}</b></div>
          </div>
          <div class="columns" v-if="MolecularTest.GenotypeMTBDRplus == 'Yes'">
            <div class="column"></div>
            <div class="column">
              <div class="columns">
                <div class="column">rpoB</div>
                <div class="column"><b>{{MolecularTest.GenotypeMTBDR_rpoB}}</b></div>
              </div>
              <div class="columns">
                <div class="column">inhA</div>
                <div class="column"><b>{{MolecularTest.GenotypeMTBDR_inhA}}</b></div>
              </div>
              <div class="columns">
                <div class="column">KatG</div>
                <div class="column"><b>{{MolecularTest.GenotypeMTBDR_KatG}}</b></div>
              </div>
            </div>
          </div>
          
          <div class="columns">
            <div class="column">Genotype MTBDRsl V1.0</div>
            <div class="column"><b>{{MolecularTest.GenotypeMTBDR_Rsl_V1}}</b></div>
          </div>
          <div class="columns" v-if="MolecularTest.GenotypeMTBDR_Rsl_V1 == 'Yes'">
            <div class="column"></div>
            <div class="column">
              <div class="columns">
                <div class="column">gyrA</div>
                <div class="column"><b>{{MolecularTest.GenotypeMTBDR_Rsl_V1_gyrA}}</b></div>
              </div>
              <div class="columns">
                <div class="column">rrs</div>
                <div class="column"><b>{{MolecularTest.GenotypeMTBDR_Rsl_V1_rrs}}</b></div>
              </div>
              <div class="columns">
                <div class="column">embB</div>
                <div class="column"><b>{{MolecularTest.GenotypeMTBDR_Rsl_V1_embB}}</b></div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">Genotype MTBDRsl V2.0</div>
            <div class="column"><b>{{MolecularTest.GenotypeMTBDR_Rsl_V2}}</b></div>
          </div>
          <div class="columns" v-if="MolecularTest.GenotypeMTBDR_Rsl_V2 == 'Yes'">
            <div class="column"></div>
            <div class="column">
              <div class="columns">
                <div class="column">gyrA</div>
                <div class="column"><b>{{MolecularTest.GenotypeMTBDR_Rsl_V2_gyrA}}</b></div>
              </div>
              <div class="columns">
                <div class="column">gyrB</div>
                <div class="column"><b>{{MolecularTest.GenotypeMTBDR_Rsl_V2_gyrB}}</b></div>
              </div>
              <div class="columns">
                <div class="column">rrs</div>
                <div class="column"><b>{{MolecularTest.GenotypeMTBDR_Rsl_V2_rrs}}</b></div>
              </div>
              <div class="columns">
                <div class="column">eis</div>
                <div class="column"><b>{{MolecularTest.GenotypeMTBDR_Rsl_V2_eis}}</b></div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">Whole genome sequencing report:</div>
            <div class="column"><b>{{MolecularTest.isWholeGenome}}</b></div>
          </div>
          <div class="columns" v-if="MolecularTest.GenotypeMTBDR_Rsl_V2 == 'Yes'">
            <div class="column"></div>
            <div class="columns">{{MolecularTest.WholeGenome}}</div>
          </div>
        </div>
        <HR/>

        <div class="box">
          <table class="table is-striped" style="width:100%;">
            <thead>
              <tr>
                <th>Drug</th>
                <th>Date Start</th>
                <th>Dose Prescribed<br/>(mg)</th>
                <th>Drug Interruption</th>
                <th>Days of Interruption</th>
                <th>Reason of Interruption</th>
                <th>Date End</th>
                <th>Total Drug Exposure <br/> (days)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="entry in Treatments.Treatments" v-bind:key="entry">
                <td >
                  {{entry.drug}}
                </td>
                <td >
                  {{entry.startDate}}
                </td>
                <td >
                  {{entry.dosePrescribed}}
                </td>
                <td >
                  {{entry.drugInterruption}}
                </td>
                <td >
                  {{entry.daysOfInterruption}}
                </td>
                <td >
                  {{entry.reasonInterruption}}
                </td>
                <td >
                  {{entry.endDate}}
                </td>
                <td >
                  {{entry.totalDrugExposure}}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="columns">
            <div class="column">Further Comment</div>
            <div class="column">{{Treatments.Comments}}</div>  
          </div>
        </div>
        <hr/>

        <div style="width:100%" class="box">
          <div class="columns">
            <div class="column">Adverse Event(s)</div>
            <div class="column"><b>{{AdverseEvents.AdverseEvents}}</b></div>
          </div>
          <table v-if="AdverseEvents.AdverseEvents == 'Yes'" class="table is-striped" style="width:100%;">
            <thead>
              <tr>
                <th>Description</th>
                <th>Drug(s)<br>Responsible</th>
                <th>Grading AE</th>
                <th>Action Taken</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="entry in AdverseEvents.EventsList" v-bind:key="entry" >
                <td>
                  <span v-html="entry.eventDescription"></span>
                </td>
                <td>
                  <span v-html="entry.drugsResponsible"></span>
                </td>
                <td >
                  {{entry.selectedgradingAEValue}}
                </td>
                <td >
                  {{entry.selectedActionTaken}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr/>

        <div style="width:100%" class="box">
          <div class="columns">
            <div class="column">Therapeutic Drug Monitoring/Pharmacokinetics (TDM/PK) performed:</div>
            <div class="column"><b>{{TherapeuticMonitoring.therapeuticDrugMonitoring}}</b></div>
          </div>
          <div class="columns" v-if="TherapeuticMonitoring.therapeuticDrugMonitoring == 'Yes'">
            <div class="column">If TDM/PK performed, specify for which drugs:</div>
            <div class="column">{{TherapeuticMonitoring.TDMPKDrugs}}</div>
          </div>
          <div class="columns" v-if="TherapeuticMonitoring.therapeuticDrugMonitoring == 'Yes'">
            <div class="column">Additional Comments:</div>
            <div class="column">{{TherapeuticMonitoring.TDMPKComments}}</div>
          </div>
        </div>
        <!-- Submission Action  -->
        <div class="field is-horizontal">
          <div class=" field-body">
            <div class="field" style="margin-top:10px; text-align:center;">
              <button class="button is-primary is-large" v-on:click="sendForm" >Submit your case</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="steps-actions" style="display:none;">
      <div class="steps-action" >
        <a ref="prevStep" href="#" data-nav="previous" class="button is-light">Previous</a>
      </div>
      <div class="steps-action" >
        <a ref="nextStep"  href="#" data-nav="next" class="button is-light">Next</a>
      </div>
    </div>
  </div>
      <div class="columns">
      <div class="column is-half">
        <button class="button is-primary is-medium is-fullwidth" v-on:click="prev" >Previous</button>
      </div>
      <div class="column is-half">
        <button class="button is-primary is-medium is-fullwidth" v-on:click="next" v-if="step < 8">Next</button>
      </div>
    </div>



</div>



</template>
<script>
  import bulmaSteps from '../../node_modules/bulma-extensions/bulma-steps/dist/js/bulma-steps.js'
  import MolecularTest from './formsteps/MolecularTest.vue'
  import TreatmentRegimenCurrent from './formsteps/TreatmentRegimenCurrent.vue'
  import AdversEvent from './formsteps/AdverseEvent.vue'
  import TherapeuticMonitoring from './formsteps/TherapeuticMonitoring.vue'
  import TBGeneralInformation from './formsteps/GeneralInformation.vue'
  import InfoTBHistory from './formsteps/InfoTBHistory.vue'
  import messages from './strings.js' 
  import AuthService from '../services/auth.service'
  import apiClient from '../api/axios.js'

export default {
    data () {
      return {
        currentError: '',
        currentLegend: '',
        step: 0,
        GI: {},
        TBHistory: {},
        MolecularTest: {},
        Treatments: {},
        AdverseEvents: {},
        TherapeuticMonitoring: {},
        recapForm: '',
        recapForPDF:'',
        riskFactor: '',
        PreviousDrug:'',
        TestMolecular: '',
        processing: false, 
        mode: 'single',
        BacteriologicalExaminations:[],
        NewBactDate: new Date(),
        NewBactSmear:'Not Done',
        NewBactCulture:'Not Done',
        NewBactSCDate:new Date(),
        DSTResults:[],
        DSTResult:{
          date: new Date(),
          H:'',
          R:'',
          E:'',
          S:'',
          Z:'',
          Am:'',
          Km:'',
          Cm:'',
          Ofx:'',
          Lfx:'',
          LfxHD:'',
          Mfx:'',
          MfxHD:'',
          Cs:'',
          PAS:'',
          Lzd:'',
          Cfz:'',
          HHD:'',
          Eto:'',
          Dlm:'',
          Bdq:'',
          CarbapenemsType:'',
          Carbapenems:'',
        },
        DSTComments:''
      }
    },
    mounted(){
      bulmaSteps.attach(); 

      //MolecularTest.TestComponent = 'Indovina?';
      this.TestMolecular = this.$refs.MolecularTestForm.TestRead
    },
    
    components:{
      MolecularTest,
      TreatmentRegimenCurrent,
      AdversEvent,
      TherapeuticMonitoring,
      TBGeneralInformation,
      InfoTBHistory
    },
    methods:{
      
      prepareGeneralInfo(){
        //this.GI.physicianName = this.$refs.TBGeneralInformation.physicianName;
        //this.$set(this.GI,'physicianName',this.$refs.TBGeneralInformation.physicianName);
        
        this.GI = {
            physicianName: this.$refs.TBGeneralInformation.physicianName,
            submissionDate: this.$refs.TBGeneralInformation.submissionDate.toLocaleDateString(),
            sex:this.$refs.TBGeneralInformation.sex,
            yearOfBirth: this.$refs.TBGeneralInformation.yearOfBirth,
            ageAtAdmission: this.$refs.TBGeneralInformation.ageAtAdmission,
            countryOfResidence: this.$refs.TBGeneralInformation.countryOfResidence,
            currentWeight: this.$refs.TBGeneralInformation.currentWeight,
            heigth: this.$refs.TBGeneralInformation.heigth,
            BMI: this.$refs.TBGeneralInformation.BMI.toFixed(2),
            BCGVaccinated: this.$refs.TBGeneralInformation.BCGVaccinated,
            riskFactorList: this.riskFactor,
            
            CD4Date: this.$refs.TBGeneralInformation.CD4Date.toLocaleDateString(),
            CD4Result: this.$refs.TBGeneralInformation.CD4Result,
            hivTest: this.$refs.TBGeneralInformation.hivTest,
            hivStatus: this.$refs.TBGeneralInformation.hivStatus,
            aidsDiagnosis:this.$refs.TBGeneralInformation.aidsDiagnosis,
            aidsDiagnosysYear: this.$refs.TBGeneralInformation.aidsDiagnosysYear,
            aidsDiagnosisOpportunisticDeasease:this.$refs.TBGeneralInformation.aidsDiagnosisOpportunisticDeasease,
            HIVViralLoad:this.$refs.TBGeneralInformation.HIVViralLoad,
            HIVViralLoadValue:this.$refs.TBGeneralInformation.HIVViralLoadValue,
            ARTDateInit: this.$refs.TBGeneralInformation.ARTDateInit.toLocaleDateString(),
            ARTDrugsandDosage:this.$refs.TBGeneralInformation.ARTDrugsandDosage.replace(/(?:\r\n|\r|\n)/g, '<br>'),
            ARTOtherDrugs:this.$refs.TBGeneralInformation.ARTOtherDrugs.replace(/(?:\r\n|\r|\n)/g, '<br>'),
            ConcomitantMedication:this.$refs.TBGeneralInformation.ConcomitantMedication.replace(/(?:\r\n|\r|\n)/g, '<br>')
        }
        
      },
      prepareTBHistory(){
        this.TBHistory ={
          previousTB:this.$refs.InfoTBHistory.previousTB,
          previousTBTypePulmonary:this.$refs.InfoTBHistory.previousTBTypePulmonary,
          previousTBTypeExtraPulmonary:this.$refs.InfoTBHistory.previousTBTypeExtraPulmonary,
          previousTBTypeExtraSpec:this.$refs.InfoTBHistory.previousTBTypeExtraSpec,
          previousTBResistance:this.$refs.InfoTBHistory.previousTBResistance,
          previousTBTerapy: this.$refs.InfoTBHistory.previousTBTerapy,
          previousTBTherapyDay: this.$refs.InfoTBHistory.selectTherapyDay,
          previousTBTherapyDrugs: this.PreviousDrug,
          previousTBTherapyOutcome: this.$refs.InfoTBHistory.Outcome,
          currentTBDate: this.$refs.InfoTBHistory.currTBE.dateEpisode.toLocaleDateString(),
          currentTBDurationSymptomsTillDiagnosis: this.$refs.InfoTBHistory.selectSymptomsTillDiagnosis,
          currentTBForm: this.$refs.InfoTBHistory.currTBE.Form,
          currentTBTypePulmonary: this.$refs.InfoTBHistory.currTBE.TypePulmonary,
          currentTBTypeExtraPulmonary: this.$refs.InfoTBHistory.currTBE.TypeExtraPulmonary,
          currentTBTypeExtraPulmonarySpec: this.$refs.InfoTBHistory.currTBE.TypeSpec,
          currentTBTResistance: this.$refs.InfoTBHistory.currTBE.Resistance,
          currentTBTResistanceSpec: this.$refs.InfoTBHistory.currTBE.ResistanceSpec,
          currentTBComment:this.$refs.InfoTBHistory.currentTBComment,
          currentTBExtension: this.$refs.InfoTBHistory.TBExtension,

          }
      },
      prepareMolecularTest(){
        this.MolecularTest ={
            XpertMTBRIF : this.$refs.MolecularTestForm.XpertMTBRIF,
            XpertMTBRIFMTB:this.$refs.MolecularTestForm.XpertMTBRIFMTB,
            XpertMTBRIFRifRes: this.$refs.MolecularTestForm.XpertMTBRIFRifRes,
            XpertMTBRIFUltra: this.$refs.MolecularTestForm.XpertMTBRIFUltra,
            XpertMTBRIFUltraMTB: this.$refs.MolecularTestForm.XpertMTBRIFUltraMTB,
            XpertMTBRIFUltraRifRes: this.$refs.MolecularTestForm.XpertMTBRIFUltraRifRes,
            GenotypeMTBDRplus: this.$refs.MolecularTestForm.GenotypeMTBDRplus,
            GenotypeMTBDR_rpoB: this.$refs.MolecularTestForm.GenotypeMTBDR_rpoB,
            GenotypeMTBDR_inhA: this.$refs.MolecularTestForm.GenotypeMTBDR_inhA,
            GenotypeMTBDR_KatG: this.$refs.MolecularTestForm.GenotypeMTBDR_KatG,
            GenotypeMTBDR_Rsl_V1: this.$refs.MolecularTestForm.GenotypeMTBDR_Rsl_V1,
            GenotypeMTBDR_Rsl_V1_gyrA: this.$refs.MolecularTestForm.GenotypeMTBDR_Rsl_V1_gyrA,
            GenotypeMTBDR_Rsl_V1_rrs: this.$refs.MolecularTestForm.GenotypeMTBDR_Rsl_V1_rrs,
            GenotypeMTBDR_Rsl_V1_embB:this.$refs.MolecularTestForm.GenotypeMTBDR_Rsl_V1_embB,
            GenotypeMTBDR_Rsl_V2: this.$refs.MolecularTestForm.GenotypeMTBDR_Rsl_V2,
            GenotypeMTBDR_Rsl_V2_gyrA: this.$refs.MolecularTestForm.GenotypeMTBDR_Rsl_V2_gyrA,
            GenotypeMTBDR_Rsl_V2_gyrB: this.$refs.MolecularTestForm.GenotypeMTBDR_Rsl_V2_gyrB,
            GenotypeMTBDR_Rsl_V2_rrs: this.$refs.MolecularTestForm.GenotypeMTBDR_Rsl_V2_rrs,
            GenotypeMTBDR_Rsl_V2_eis: this.$refs.MolecularTestForm.GenotypeMTBDR_Rsl_V2_eis,
            isWholeGenome: this.$refs.MolecularTestForm.isWholeGenome,
            WholeGenome: this.$refs.MolecularTestForm.WholeGenome
        }
      },
      prepareTreatments(){
        this.Treatments ={
          Treatments: this.$refs.TreatmentRegimenCurrent.treatments,
          Comments: this.$refs.TreatmentRegimenCurrent.furtherCommet
        } 
      },
      prepareAdverseEvents(){
        this.AdverseEvents = {
          AdverseEvents: this.$refs.AdverseEventComponent.adverseEvent,
          EventsList: this.$refs.AdverseEventComponent.adverseEvents,

        }
      },
      prepareTherapeuticDrugMonitor(){
        this.TherapeuticMonitoring = {
          therapeuticDrugMonitoring: this.$refs.TherapeuticMonitoringComponent.therapeuticDrugMonitoring,
          TDMPKDrugs: this.$refs.TherapeuticMonitoringComponent.TDMPKDrugs,
          TDMPKComments: this.$refs.TherapeuticMonitoringComponent.TDMPKComments
        }
      },
      prepareRecap(){
        this.riskFactor = '';
        if (this.$refs.TBGeneralInformation.RiskFactor.CurrentAlcolAbuser){
          this.riskFactor += 'Current Alcohol Abuser<br/>';
        }
        if (this.$refs.TBGeneralInformation.RiskFactor.Prisoner){
          this.riskFactor += 'Prisoner<br/>';
        }
        if (this.$refs.TBGeneralInformation.RiskFactor.IVDrugUser){
          this.riskFactor += 'IV Drug User<br/>';
        }
        if (this.$refs.TBGeneralInformation.RiskFactor.ImmunoDepressiveConditions){
          this.riskFactor += 'Immunosuppressive Conditions<br/>';
        }
        if (this.$refs.TBGeneralInformation.RiskFactor.DiabetesMellitus){
          this.riskFactor += 'Diabetes Mellitus<br/>';
        }
        if (this.$refs.TBGeneralInformation.RiskFactor.Transplantation){
          this.riskFactor += 'Transplantation<br/>';
        }
        if (this.$refs.TBGeneralInformation.RiskFactor.Malnutrition){
          this.riskFactor += 'Malnutrition<br/>';
        }
        if (this.$refs.TBGeneralInformation.RiskFactor.RenalImpairment_Dyalisys){
          this.riskFactor += 'Renal Impairment/Dialysis<br/>';
        }
        if (this.$refs.TBGeneralInformation.RiskFactor.Pregnancy){
          this.riskFactor += 'Pregnancy: '+ this.$refs.TBGeneralInformation.Pregnancy_Weeks + '<br/>';
        }
        if (this.$refs.TBGeneralInformation.RiskFactor.Hepatitis){
          this.riskFactor += 'Hepatitis, please specify:' + this.$refs.TBGeneralInformation.HepatSpec + '<br/>';
        }
        if (this.$refs.TBGeneralInformation.RiskFactor.Other){
          this.riskFactor += 'Other:' + this.$refs.TBGeneralInformation.Other_Spec + '<br/>';
        }
        if (this.$refs.TBGeneralInformation.RiskFactor.None){
          this.riskFactor += 'None<br/>';
        }

        this.PreviousDrug = '';
        if  (this.$refs.InfoTBHistory.PreviousDrugs.INH){
          this.PreviousDrug += 'INH<br/>'
        }
        if  (this.$refs.InfoTBHistory.PreviousDrugs.RMPO){
          this.PreviousDrug += 'RMP<br/>'
        }
        if  (this.$refs.InfoTBHistory.PreviousDrugs.ETB){
          this.PreviousDrug += 'ETB<br/>'
        }
        if  (this.$refs.InfoTBHistory.PreviousDrugs.Strepto){
          this.PreviousDrug += 'Strepto<br/>'
        }
        if  (this.$refs.InfoTBHistory.PreviousDrugs.PZA){
          this.PreviousDrug += 'PZA<br/>'
        }
        if  (this.$refs.InfoTBHistory.PreviousDrugs.AMK){
          this.PreviousDrug += 'AMK<br/>'
        }
        if  (this.$refs.InfoTBHistory.PreviousDrugs.KANA){
          this.PreviousDrug += 'KANA<br/>'
        }
        if  (this.$refs.InfoTBHistory.PreviousDrugs.CAPREO){
          this.PreviousDrug += 'CAPREO<br/>'
        }
        if  (this.$refs.InfoTBHistory.PreviousDrugs.OFX){
          this.PreviousDrug += 'OFX<br/>'
        }
        if  (this.$refs.InfoTBHistory.PreviousDrugs.LFX){
          this.PreviousDrug += 'LFX<br/>'
        }
        if  (this.$refs.InfoTBHistory.PreviousDrugs.LFXHD){
          this.PreviousDrug += 'LFXHD<br/>'
        }
        if  (this.$refs.InfoTBHistory.PreviousDrugs.MFX){
          this.PreviousDrug += 'MFX<br/>'
        }
        if  (this.$refs.InfoTBHistory.PreviousDrugs.MFXHD){
          this.PreviousDrug += 'MFXHD<br/>'
        }
        if  (this.$refs.InfoTBHistory.PreviousDrugs.CYCLO){
          this.PreviousDrug += 'CYCLO<br/>'
        }
        if  (this.$refs.InfoTBHistory.PreviousDrugs.PAS){
          this.PreviousDrug += 'PAS<br/>'
        }
        if  (this.$refs.InfoTBHistory.PreviousDrugs.LZD){
          this.PreviousDrug += 'LZD<br/>'
        }
        if  (this.$refs.InfoTBHistory.PreviousDrugs.CFZ){
          this.PreviousDrug += 'CFZ<br/>'
        }
        if  (this.$refs.InfoTBHistory.PreviousDrugs.INH_HD){
          this.PreviousDrug += 'INH_HD<br/>'
        }
        if  (this.$refs.InfoTBHistory.PreviousDrugs.ETO){
          this.PreviousDrug += 'ETO<br/>'
        }
        if  (this.$refs.InfoTBHistory.PreviousDrugs.DLM){
          this.PreviousDrug += 'DLM<br/>'
        }
        if  (this.$refs.InfoTBHistory.PreviousDrugs.BDQ){
          this.PreviousDrug += 'KABDQNA<br/>'
        }
        if  (this.$refs.InfoTBHistory.PreviousDrugs.Carbapenems){
          this.PreviousDrug += 'Carbapenems (' + this.$refs.InfoTBHistory.PreviousDrugs.CarbapenemsSpec +')<br/>'
        }

      },

      prev() {
        this.step--;
        this.$refs.prevStep.click();
      },
      next() {
        this.prepareRecap();

        switch(this.step){
          case 0:
            this.riskFactor = '';
            this.prepareGeneralInfo();
            break;
          case 1:
            this.prepareTBHistory();
            break;
          case 2:
            if (this.BacteriologicalExaminations.length == 0){
              this.currentError = messages.error_messages.BACTERIOLOGICAL_EXAM_MISSING;
              return;
            } else {
              this.currentError = '';
              this.currentLegend = messages.legend_messages.DST_RESULT_VALUES;
            }
            break;
          case 3:
            if (this.DSTResults.length == 0){
              this.currentError = messages.error_messages.DST_MISSING;
              return;
            } else {
              this.currentError = '';
              this.currentLegend = ''
            }
            break;
          case 4:
            this.prepareMolecularTest();
            break;
          case 5:
            if (this.$refs.TreatmentRegimenCurrent.treatments.length == 0){
              this.currentError = messages.error_messages.TREATMENT_MISSING;
              return;
            } else {
              this.prepareTreatments();
              this.currentError = '';
            }
            break;
          case 6:
            if (this.$refs.AdverseEventComponent.adverseEvent == 'Yes' && this.$refs.AdverseEventComponent.adverseEvents.length == 0 ){
              this.currentError = messages.error_messages.ADVERSE_MISSING;
              return;
            } else {
              this.currentError = '';
              this.prepareAdverseEvents();
            }
            break;
          case 7:
            this.prepareTherapeuticDrugMonitor();
            break;
          default:
            this.currentError = '';
            this.currentLegend = '';
            break;
        }
        this.step++;
        
        this.$refs.nextStep.click();

      },
      getReverseNumbers:function(start,stop){
        return new Array(start-stop).fill(start).map((n,i)=>n-i);
      },
      getNumbers:function(start,stop){
        return new Array(stop-start).fill(start).map((n,i)=>n+i);
      },
      submit() {
        alert('Submit to blah and show blah and etc.');      
      },
      addExamination(){
        if (this.processing === true) {
          return;
        } 
        this.processing = true;
        const niceDate = this.NewBactDate.toLocaleDateString();
        const niceSDDate  = this.NewBactSCDate.toLocaleDateString();
        const stringSmear = this.NewBactSmear.toString();
        const stringCulture = this.NewBactCulture.toString();
        this.BacteriologicalExaminations.push({collectionDate:niceDate,smear:stringSmear,culture:stringCulture,SCResult:niceSDDate});
        this.NewBactSCDate = new Date();
        this.NewBactSmear = 'Not Done';
        this.NewBactDate = new Date();
        this.NewBactCulture = 'Not Done';
        setTimeout(() => {
          this.processing = false;
        }, 1000);
      },
      deleteExamination(examination){
        this.BacteriologicalExaminations.splice(this.BacteriologicalExaminations.indexOf(examination),1);
      },
      addDST(){
        if (this.processing === true) {
          return;
        } 
        this.processing = true;
        const niceDate = this.DSTResult.date.toLocaleDateString();
        this.DSTResults.push({
          date:niceDate,
          H:this.DSTResult.H,
          R:this.DSTResult.R,
          E:this.DSTResult.E,
          S:this.DSTResult.S,
          Z:this.DSTResult.Z,
          Am:this.DSTResult.Am,
          Km:this.DSTResult.Km,
          Cm:this.DSTResult.Cm,
          Ofx:this.DSTResult.Ofx,
          Lfx:this.DSTResult.Lfx,
          LfxHD:this.DSTResult.LfxHD,
          Mfx:this.DSTResult.Mfx,
          MfxHD:this.DSTResult.MfxHD,
          Cs:this.DSTResult.Cs,
          PAS:this.DSTResult.PAS,
          Lzd:this.DSTResult.Lzd,
          Cfz:this.DSTResult.Cfz,
          HHD:this.DSTResult.HHD,
          Eto:this.DSTResult.Eto,
          Dlm:this.DSTResult.Dlm,
          Bdq:this.DSTResult.Bdq,
          CarbapenemsType:this.DSTResult.CarbapenemsType,
          Carbapenems:this.DSTResult.Carbapenems,
        });
        this.DSTResult.date = new Date();
        this.DSTResult.H = '';
        this.DSTResult.R = '';
        this.DSTResult.E = '';
        this.DSTResult.S = '';
        this.DSTResult.Z = '';
        this.DSTResult.Am = '';
        this.DSTResult.Km = '';
        this.DSTResult.Cm = '';
        this.DSTResult.Ofx = '';
        this.DSTResult.Lfx = '';
        this.DSTResult.LfxHD = '';
        this.DSTResult.Mfx = '';
        this.DSTResult.MfxHD = '';
        this.DSTResult.Cs = '';
        this.DSTResult.PAS = '';
        this.DSTResult.Lzd = '';
        this.DSTResult.Cfz = '';
        this.DSTResult.HHD = '';
        this.DSTResult.Eto = '';
        this.DSTResult.Dlm = '';
        this.DSTResult.Bdq = '';
        this.DSTResult.CarbapenemsType = '';
        this.DSTResult.Carbapenems = '';
        setTimeout(() => {
          this.processing = false;
        }, 1000);
      },
      deleteDST(dst){
        this.DSTResults.splice(this.DSTResults.indexOf(dst),1);
      },
    sendForm(){
      const res ={
        email: AuthService.getEmail(),
        oktaId: AuthService.getSub(),
        GeneralInformation : this.GI,
        InfoTBHistory: this.TBHistory,
        BacteriologicalExaminations: this.BacteriologicalExaminations,
        DST: {DSTResult: this.DSTResults, DSTComment:this.DSTComments},
        MolecularTest: this.MolecularTest,
        Treatments: this.Treatments,
        AdverseEvents: this.AdverseEvents,
        TherapeuticMonitoring: this.TherapeuticMonitoring,

      };
      this.submitCase(res);
    },
    async submitCase(currentData) {
        try {
          const response = await apiClient.post('/submitcase', currentData);
          
          if (response.data.newcaseProtocol) {
            this.$router.push({path: 'submitted'});
          }
        } catch (error) {
          console.error('Submit case error:', error);
          // Handle specific errors if needed
          throw error;
        }
    }
  } 
}
</script>