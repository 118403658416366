const error_messages = {
    BACTERIOLOGICAL_EXAM_MISSING: 'Please  add least 1 Examination. Insert values then CLICK on ADD EXAMINATION GREEN BUTTON',
    DST_MISSING: 'Please  add least 1 DST Result. Insert values then CLICK on ADD DST GREEN BUTTON',
    TREATMENT_MISSING: 'Treatment Regimen in current TB episode. Please note: add least 1 Treatment. Insert values then CLICK on ADD TREATMENT BUTTON',
    ADVERSE_MISSING: 'Adverse Event(s). Please note: you\'ve selected the presence of Adverse Event. Please add least 1 Treatment. Insert values then CLICK on ADD ADVERSE EVENT BUTTON'
};

const legend_messages = {
    DST_RESULT_VALUES: 'DST = Drug Susceptibility Testing / HD = High Dose / R = Resistant / S = Susceptible / ND = Not Done / U = Uknown'
};
export default  {error_messages , legend_messages};