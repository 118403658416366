<template>
    <div class ="box">
        <table class="table is-striped" style="width:100%;">
            <thead>
            <tr>
                <th>ID</th>
                <th>Date</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="entry in listOfCases" v-bind:key="entry">
                    <td>{{entry.protocol}}</td>
                    <td>{{months[entry.monthSubmission - 1]}} {{entry.daySubmission}}, {{entry.yearSubmission}}</td>
                    <td>Pretomanid</td>
                </tr>
            </tbody>
        </table>

        <router-link class="button is-primary" to="/submission">Submit New Case</router-link>
    </div>
    
</template>

<script>
import AuthService from '../services/auth.service'
import apiClient from '../api/axios';
export default {
    data(){
        return {
            listOfCases:[],
            months:['January','February','March','April','May','June','July','August','September','October','November','December']
        }
    },
    created(){
        this.getCases()

    },
    methods:{
        async getCases(){
        const response = await apiClient.get('/casesubmitted/' + AuthService.getSub())
        this.listOfCases=response.data;
        if(response.data.length == 0){
            this.$router.replace('/submission')
        }
      }
    }
}
</script>