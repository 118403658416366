<template>
    <div class="box">


    <div class="field is-horizontal">
        <div class="field-label is-normal">
            <label class="label">Physician's Name:</label>
        </div>
        <div class="field-body">
            <div class="field">
                <div class="control">
                    <input class="input" id="name" name="name" v-model="physicianName">
                </div>
            </div>
            </div>
        </div>

        <div class="field is-horizontal">
            <div class="field-label is-normal">
                <label class="label">Date Case Submitted:</label>
                </div>
                <div class="field-body">
                <div class="field">
                    <div class="control">
                    <v-date-picker 
                      id="btnSubmitDate" 
                      title-position="left" 
                      v-model="submissionDate"   
                      :popover="{ visibility: 'click', placement: 'bottom' }"
                      >
                        <template v-slot:default="{ inputValue, togglePopover }">
                        <button  class="button is-primary"                       
                        @click="togglePopover"
                        >
                        <i class="fas fa-calendar-check " style="margin-right:8px;"></i>{{submissionDate.toLocaleDateString()}}
                        </button>
                      </template>
                    </v-date-picker>

                    </div>
                </div>
            </div>
        </div>

        <div class="box field is-horizontal">
            <div class="field-body">
            <div class="field">
                <label class="label">Sex:</label>
                <input class="is-checkradio" id="sex1" type="radio" name="sex" value="M" v-model="sex" >
                <label for="sex1">M</label>
                <input class="is-checkradio" id="sex2" type="radio" name="sex" value="F" v-model="sex">
                <label for="sex2">F</label>
            </div>

            <div class="field">
                <label class="label">Year of Birth:</label>
                <div class="select">
                    <select name="" id="input" class="form-control" v-model="yearOfBirth" >
                    <option v-for="n in getNumbers(1940,2020)" :value="n"  v-bind:key="n">{{n}}</option>
                    </select>
                </div>
            </div>

            <div class="field">
                <label class="label">Age at Admission:</label>
                <div class="select">
                    <select name="" id="input" class="form-control" v-model="ageAtAdmission" >
                    <option v-for="n in getNumbers(0,80)" :value="n"  v-bind:key="n">{{n}}</option>
                    </select>
                </div>

            </div>
            </div>
        </div>

        <div class="box field is-horizontal">
            <div class="field-body">
            <div class="field">
                <label class="label">Country of Residence:</label>
                <input class="input" id="countryResidence" type="text" name="countryResidence" v-model="countryOfResidence">
            </div>
            <div class="field">
                <label class="label">Current Weight (Kg):</label>
                <div class="select">
                    <select name="" id="input" class="form-control" v-model="currentWeight" @change="onChange()">
                    <option v-for="n in getNumbers(1,120)" :value="n"  v-bind:key="n">{{n}}</option>
                    </select>
                </div>
            </div>
            <div class="field">
                <label class="label">Height (cm):</label>
                <div class="select">
                    <select name="" id="input" class="form-control" v-model="heigth" @change="onChange()">
                    <option v-for="n in getNumbers(50,220)" :value="n"  v-bind:key="n" >{{n}}</option>
                    </select>
                </div>
            </div>

            <div class="field">
                <label class="label">BMI:</label>
                <label class="input" id="BMI" type="text" name="BMI">{{BMI.toFixed(2)}}</label>
            </div>
            </div>
        </div>
        
        <div class="card field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">BCG Vaccinated:</label>
          </div>
          <div class="field-body">
            <div class="field">
              <input class="is-checkradio" id="bcg_Vaccinated1" type="radio" v-model="BCGVaccinated" value="Yes" name="bcg_Vaccinated">
              <label for="bcg_Vaccinated1">Yes</label>
              <input class="is-checkradio" id="bcg_Vaccinated2" type="radio" v-model="BCGVaccinated" value="No" name="bcg_Vaccinated">
              <label for="bcg_Vaccinated2">No</label>
              <input class="is-checkradio" id="bcg_Vaccinated3" type="radio" v-model="BCGVaccinated" value="Unknown" name="bcg_Vaccinated">
              <label for="bcg_Vaccinated3">Unknown</label>
            </div>
          </div>
        </div>

        <div class="card field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Co-morbidities,<br> Risk Factor:</label>
          </div>
          <div class="field-body">
            <div class="field has-text-left">
              <p>
                <input class="is-checkradio" id="riskFactorAlchol" type="checkbox" v-model="RiskFactor.CurrentAlcolAbuser" name="riskFactorAlchol">
                <label for="riskFactorAlchol">Current alcohol abuser</label>
              </p>
              <p>
                <input class="is-checkradio" id="riskFactorPrisoner" type="checkbox" name="riskFactorPrisoner" v-model="RiskFactor.Prisoner">
                <label for="riskFactorPrisoner">Prisoner</label>
              </p>
              <p>
                <input class="is-checkradio" id="riskDrugUser" type="checkbox" name="riskDrugUser" v-model="RiskFactor.IVDrugUser">
                <label for="riskDrugUser">IV Drug User</label>
              </p>
              <p>
                <input class="is-checkradio" id="riskImmunodepressive" type="checkbox" name="riskImmunodepressive" v-model="RiskFactor.ImmunoDepressiveConditions">
                <label for="riskImmunodepressive">Immunodepressive conditions</label>
              </p>
            </div>

            <div class="field has-text-left">
              <p>
                <input class="is-checkradio" id="riskFactorDiabete" type="checkbox" name="riskFactorDiabete" v-model="RiskFactor.DiabetesMellitus">
                <label for="riskFactorDiabete">Diabetes Mellitus</label>
              </p>
              <p>
                <input class="is-checkradio" id="riskFactorMalnutrition" type="checkbox" name="riskFactorMalnutrition" v-model="RiskFactor.Malnutrition">
                <label for="riskFactorMalnutrition">Malnutrition</label>
              </p>
              <p>
                <input class="is-checkradio" id="riskTransplantation" type="checkbox" name="riskTransplantation" v-model="RiskFactor.Transplantation">
                <label for="riskTransplantation">Transplantation</label>
              </p>
              <p>
                <input class="is-checkradio" id="riskRenal" type="checkbox" name="riskRenal" v-model="RiskFactor.RenalImpairment_Dialysys">
                <label for="riskRenal">Renal impairment/Dialysys</label>
              </p>
              <p>
                <input class="is-checkradio" id="riskNone" type="checkbox" name="riskNone" v-model="RiskFactor.None">
                <label for="riskNone">None</label>
              </p>
            </div>

            <div class="field has-text-left">
             <p>
                <input class="is-checkradio" id="riskFactoHepatitis" type="checkbox" v-model="RiskFactor.Hepatitis" name="riskFactoHepatitis">
                <label for="riskFactoHepatitis">Hepatitis</label>
                <label :style="{visibility: RiskFactor.Hepatitis ? 'visible' : 'hidden'}" >specify</label>
                <input :style="{visibility: RiskFactor.Hepatitis ? 'visible' : 'hidden'}" class="input" id="HepatitisSpecify" v-model="HepatSpec" type="text" name="HepatitisSpecify">
              </p>
             <p>
                <input class="is-checkradio" id="riskFactorPregnancy" type="checkbox" v-model="RiskFactor.Pregnancy" name="riskFactorPregnancy">
                <label for="riskFactorPregnancy">Pregnancy</label>
                <label :style="{visibility: RiskFactor.Pregnancy ? 'visible' : 'hidden'}" for="riskPregnancy">Weeks</label>
                <input :style="{visibility: RiskFactor.Pregnancy ? 'visible' : 'hidden'}" class="input" id="PregnancyWeeks" type="text" v-model="Pregnancy_Weeks" name="PregnancyWeeks">
              </p>
              <p>
                <input class="is-checkradio" id="riskFactorOther"  v-model="RiskFactor.Other" type="checkbox" name="riskFactorOther">
                <label for="riskFactorOther">Other</label>

                <label  :style="{visibility: RiskFactor.Other ? 'visible' : 'hidden'}"  for="riskFactorOther">specify</label>
                <input  :style="{visibility: RiskFactor.Other ? 'visible' : 'hidden'}" v-model="Other_Spec" class="input" id="OtherSpecify" type="text" name="OtherSpecify">
              </p>
  
            </div>

          </div>
        </div>

        <div class="box">
          <div class="field is-horizontal" style="margin-top:1.5em;">
            <div class="field-label is-normal">
              <label class="label">HIV Status:</label>
            </div>
            <div class="field-body">
              <div id="status" class="field-body">
                <div class="label is-normal">
                  <label class="label">HIV Test Offered:</label>
                </div>

                <div class="field">
                  <input class="is-checkradio" id="exampleRadioInline1" type="radio" value="Yes" v-model="hivStatus" name="hiv_testOffered">
                  <label for="exampleRadioInline1">Yes</label>
                  <input class="is-checkradio" id="exampleRadioInline2" type="radio" value="No" v-model="hivStatus" name="hiv_testOffered">
                  <label for="exampleRadioInline2">No</label>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal" v-if="hivStatus=='Yes'">
            <div class="field-label is-normal">
            </div>
            <div class="field-body"  id="hi_test">
              <div class="label is-normal">
                <label class="label">HIV Test :</label>
              </div>
              <div class="field">
                <input class="is-checkradio" id="hivTestNo" type="radio" value="Negative" v-model="hivTest" name="hiv_test">
                <label for="hivTestNo">Negative</label>
                <input class="is-checkradio" id="hivTestYes" type="radio" value="Positive" v-model="hivTest" name="hiv_test">
                <label for="hivTestYes">Positive</label>
                <input class="is-checkradio" id="hivTestNA" type="radio" value="Not Done" v-model="hivTest" name="hiv_test">
                <label for="hivTestNA">Not done</label>
              </div>
            </div>
          </div>

          <div class="field is-horizontal" v-if="hivTest=='Positive'">
            <div class="field-label is-normal">
            </div>
            <div class="field-body"  id="hi_test">
              <div class="label is-normal">
                <label class="label">Aids Diagnosis :</label>
              </div>
              <div class="field">
                <input class="is-checkradio" id="aidsDiagnosisYes" type="radio" value="Yes" v-model="aidsDiagnosis" name="aidsDiagnosis">
                <label for="aidsDiagnosisYes">Yes</label>
                <label v-if="aidsDiagnosis=='Yes'" >and state year: </label>
                <div v-if="aidsDiagnosis=='Yes'" class="select">
                    <select name="" id="input" class="form-control" v-model="aidsDiagnosysYear" >
                      <option v-for="n in getReverseNumbers(2020,1959)" :value="n"  v-bind:key="n">{{n}}</option>
                    </select>
                </div>
                <input class="is-checkradio" id="aidsDiagnosisOpportunistic" type="radio" value="Opportunistic Disease" v-model="aidsDiagnosis" name="aidsDiagnosis">
                <label for="aidsDiagnosisOpportunistic">Opportunistic diseases please state which:</label>
                <input v-if="aidsDiagnosis=='Opportunistic Disease'" v-model="aidsDiagnosisOpportunisticDeasease" class="input" id="aidsOpportunisticDiseaseSpec" type="text" name="aidsOpportunisticDiseaseSpec">
    
              </div>
            </div>
          </div>

          <div class="field is-horizontal" v-if="hivTest=='Positive'">
            <div class="field-label is-normal">
            </div>
            <div class="field-body"  id="hi_test">
              <div class="label is-normal">
                <label class="label">Last CD4 Count :</label>
              </div>
              <div class="field">
                  <label class="label" style="font-weight: normal !important;" for="btnCD4Date">Date: </label>
                <v-date-picker 
                  id="btnCD4Date" 
                  title-position="left" 
                  v-model="CD4Date"   
                  :popover="{ visibility: 'click', placement: 'bottom' }"
                  >
                    <template v-slot:default="{ inputValue, togglePopover }">
                    <button  class="button is-primary"                       
                    @click="togglePopover"
                    >
                    <i class="fas fa-calendar-check " style="margin-right:8px;"></i>{{CD4Date.toLocaleDateString()}}
                    </button>
                  </template>
                </v-date-picker>


              </div>
              <div class="field">
                <label class="label" style="font-weight: normal !important;" for="CD4Result">Result: </label>
                <input class="input" id="CD4Result" type="text" v-model="CD4Result" name="CD4Result">
              </div>
            </div>
          </div>

          <div class="field is-horizontal" v-if="hivTest=='Positive'">
            <div class="field-label is-normal">
            </div>
            <div class="field-body"  id="hi_test">
              <div class="label is-normal">
                <label class="label">Last HIV Viral Load :</label>
              </div>
              <div class="field">
                <input class="is-checkradio" id="HIVViralLoadNoDetect" type="radio" value="Not Detectable" v-model="HIVViralLoad" name="hiv_viral_load">
                <label for="HIVViralLoadNoDetect">Not Detectable</label>
              </div>
              <div class="field">
                <input class="is-checkradio" id="HIVViralLoadNDetect" type="radio" value="Detectable" v-model="HIVViralLoad" name="hiv_viral_load">
                <label for="HIVViralLoadNDetect">Detectable</label>
              </div>
              <div class="field">
                <input class="is-checkradio" id="HIVViralLoadNotDone" type="radio" value="Not Done" v-model="HIVViralLoad" name="hiv_viral_load">
                <label for="HIVViralLoadNotDone">Not Done</label>
              </div>
            </div>
          </div>
          <div class="field is-horizontal" v-if="HIVViralLoad=='Detectable'">
            <div class="field-label is-normal">
            </div>
            <div class="field-body"  id="hi_test">
              <div class="field">
              </div>

              <div class="label is-normal">
              </div>
              <div class="field">
                  <label style="font-weight: normal !important;" class="label">please specify value:</label>
                  <input class="input" id="ViralLoadValue" v-model="HIVViralLoadValue" type="text" name="ViralLoadValue">
              </div>
            </div>
          </div>
        </div>
        <div class="box">
          <div class="field is-horizontal" v-if="hivTest=='Positive'">
              <div class="field-label is-normal">
                <label class="label">ART:</label>
              </div>
              <div class="field-body" id="HIVArt">
                <div class="label is-normal">
                  <label class="label">Date of initiation of the current regimen:</label>
                </div>
                <div class="field">
                  <v-date-picker 
                  id="btnARTDate" 
                  title-position="left" 
                  v-model="ARTDateInit"   
                  :popover="{ visibility: 'click', placement: 'bottom' }"
                  >
                    <template v-slot:default="{ inputValue, togglePopover }">
                    <button  class="button is-primary"                       
                    @click="togglePopover"
                    >
                    <i class="fas fa-calendar-check " style="margin-right:8px;"></i>{{ARTDateInit.toLocaleDateString()}}
                    </button>
                  </template>
                </v-date-picker>
                </div>              
              </div>
          </div>
          <div class="field is-horizontal" v-if="hivTest=='Positive'">
              <div class="field-label is-normal">
              </div>
              <div class="field-body" id="HIVArt">
                <div class="label is-normal">
                  <label class="label">Drug and dosages:</label>
                </div>
                <div class="field">
                  <textarea class="textarea" v-model="ARTDrugsandDosage" placeholder="List all drugs and dosages"></textarea>
                </div>              
              </div>

            </div>
        </div>
        <div class="box">
          <div class="field is-horizontal" >
              <div class="field-label is-normal">
                <label class="label">Concomitant medications:</label>
              </div>
              <div class="field-body" id="HIVArt">
                <div class="field">
                  <textarea class="textarea" v-model="ConcomitantMedication" placeholder="Other drugs including cotrimoxazole, pentamidine
and other drugs the patient may be taking for comorbidities"></textarea>
                </div>
              </div>
          </div>
        </div>

    </div>
</template>

<script>
import AuthService from '../../services/auth.service'

export default {
    name: 'TBGeneralInformation',
    data(){ 
        return {
            physicianName: AuthService.getName().toUpperCase(),
            submissionDate: new Date(),
            sex:'',
            yearOfBirth: 1940,
            ageAtAdmission: 0,
            countryOfResidence: '',
            currentWeight: 0,
            heigth: 0,
            BMI: 0,
            BCGVaccinated: '',
            RiskFactor:{
                CurrentAlcolAbuser: false,
                Prisoner: false,
                IVDrugUser: false,
                ImmunoDepressiveConditions: false,
                DiabetesMellitus: false,
                Malnutrition: false,
                Transplantation: false,
                RenalImpairment_Dialysys: false,
                None: false,
                Pregnancy: false,
                Hepatitis: false,
                Other: false,
            },
            Pregnancy_Weeks: 0,
            HepatSpec: '',
            Other_Spec: '',
            
            CD4Date: new Date(),
            CD4Result: '',
            hivTest: '',
            hivStatus: '',
            aidsDiagnosis:'',
            aidsDiagnosysYear: 2020,
            aidsDiagnosisOpportunisticDeasease:'',
            HIVViralLoad:'',
            HIVViralLoadValue:'',
            ARTDateInit: new Date(),
            ARTDrugsandDosage:'',
            ARTOtherDrugs:'',
            ConcomitantMedication:''
            
        }
    },
    methods: {
          handlePickerClick(e) {
            console.log('Picker clicked', e);
          },
          handleButtonClick(e) {
            console.log('Button clicked', e);
          },
        getReverseNumbers:function(start,stop){
            return new Array(start-stop).fill(start).map((n,i)=>n-i);
        },
        getNumbers:function(start,stop){
            return new Array(stop-start).fill(start).map((n,i)=>n+i);
        } ,
        onChange(){
           this.BMI = this.currentWeight/Math.pow((this.heigth/100), 2 );
        }
    }
}
</script>
<style>
.vc-popover-content-wrapper {
  z-index: 9999 !important;
}
</style>