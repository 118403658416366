// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import VCalendar from 'v-calendar'
import vueDebounce from 'vue-debounce'
import store from './store'
require('./assets/sass/style.scss');

// Use VCalendar plugin
Vue.use(VCalendar)

//

Vue.config.productionTip = false

Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the element
    el.focus()
  }
})

/* eslint-disable no-new */
new Vue({
  render: h => h(App),
  router,
  store,
  components: { App,vueDebounce }
}).$mount('#app')
