
    <template>
        <div class="box">
            <div class="field is-horizontal" style="margin-top:1.5em;">
            <div class="field-label is-normal">
                <label class="label">Xpert MTB/RIF</label>
            </div>
            <div class="field-body">
                <div id="status" class="field-body">
                <div class="label is-normal">
                </div>

                <div class="field ">
                    <input class="is-checkradio" id="XpertMTBRIFYes" type="radio" value="Yes" v-model="XpertMTBRIF" name="XpertMTBRIFYes">
                    <label for="XpertMTBRIFYes">Yes</label>
                    <input class="is-checkradio" id="XpertMTBRIFNo" type="radio" value="No" v-model="XpertMTBRIF" name="XpertMTBRIFNo">
                    <label for="XpertMTBRIFNo">No</label>
                    <input class="is-checkradio" id="XpertMTBRIFNotDone" type="radio" value="Not Done" v-model="XpertMTBRIF" name="XpertMTBRIFNotDone">
                    <label for="XpertMTBRIFNotDone">Not Done</label>
                </div>
                </div>
            </div>
        </div>

        <div class="field is-horizontal" v-if="XpertMTBRIF == 'Yes'">
            <div class="field-label is-normal">
                <label class="label">MTB :</label>
            </div>
            <div class="field-body"  id="hi_test">
                <div class="field has-text-left">
                    <input class="is-checkradio" id="MTBPositive" type="radio" value="Unknow" v-model="XpertMTBRIFMTB" name="MTBPositive">
                    <label for="MTBPositive">Positive</label>
                    <input class="is-checkradio" id="MTBNegative" type="radio" value="Negative" v-model="XpertMTBRIFMTB" name="MTBNegative">
                    <label for="MTBNegative">Negative</label>
                    <input class="is-checkradio" id="MTBError" type="radio" value="Error/Invalid" v-model="XpertMTBRIFMTB" name="MTBError">
                    <label for="MTBError">Error/Invalid</label>
                    <input class="is-checkradio" id="MTBTrace" type="radio" value="Traced" v-model="XpertMTBRIFMTB" name="MTBError">
                    <label for="MTBTrace">Trace</label>
                </div>
            </div>
        </div>
        <div class="field is-horizontal" v-if="XpertMTBRIF == 'Yes'">
            <div class="field-label is-normal">
                <label class="label ">Rif Resistant :</label>
            </div>
            <div class="field-body"  id="hi_test">
                <div class="field has-text-left">
                    <input class="is-checkradio" id="RifResDetected" type="radio" value="Detected" v-model="XpertMTBRIFRifRes" name="RifResDetected">
                    <label for="RifResDetected">Detected</label>
                    <input class="is-checkradio" id="RifResNotDetected" type="radio" value="Not Detected" v-model="XpertMTBRIFRifRes" name="RifResNotDetected">
                    <label for="RifResNotDetected">Not Detected</label>
                    <input class="is-checkradio" id="RifResNotIndeterminate" type="radio" value="Indeterminate" v-model="XpertMTBRIFRifRes" name="RifResNotIndeterminate">
                    <label for="RifResNotIndeterminate">Indeterminate</label>
                </div>
            </div>
        </div>



        <div class="field is-horizontal" style="margin-top:1.5em;">
            <div class="field-label is-normal">
                <label class="label">Xpert ULTRA</label>
            </div>
            <div class="field-body">
                <div id="status" class="field-body">
                <div class="label is-normal">
                </div>

                <div class="field ">
                    <input class="is-checkradio" id="XpertMTBRIFUltraYes" type="radio" value="Yes" v-model="XpertMTBRIFUltra" name="XpertMTBRIFUltraYes">
                    <label for="XpertMTBRIFUltraYes">Yes</label>
                    <input class="is-checkradio" id="XpertMTBRIFUltraNo" type="radio" value="No" v-model="XpertMTBRIFUltra" name="XpertMTBRIFUltraNo">
                    <label for="XpertMTBRIFUltraNo">No</label>
                    <input class="is-checkradio" id="XpertMTBRIFUltraNotDone" type="radio" value="Not Done" v-model="XpertMTBRIFUltra" name="XpertMTBRIFUltraNotDone">
                    <label for="XpertMTBRIFUltraNotDone">Not Done</label>
                </div>
                </div>
            </div>
        </div>

        <div class="field is-horizontal" v-if="XpertMTBRIFUltra == 'Yes'">
            <div class="field-label is-normal">
                <label class="label">MTB :</label>
            </div>
            <div class="field-body"  id="hi_test">
                <div class="field has-text-left">
                    <input class="is-checkradio" id="UltraMTBPositive" type="radio" value="Unknow" v-model="XpertMTBRIFUltraMTB" name="UltraMTBPositive">
                    <label for="UltraMTBPositive">Positive</label>
                    <input class="is-checkradio" id="UltraMTBNegative" type="radio" value="Negative" v-model="XpertMTBRIFUltraMTB" name="UltraMTBNegative">
                    <label for="UltraMTBNegative">Negative</label>
                    <input class="is-checkradio" id="UltraMTBError" type="radio" value="Error/Invalid" v-model="XpertMTBRIFUltraMTB" name="UltraMTBError">
                    <label for="UltraMTBError">Error/Invalid</label>
                    <input class="is-checkradio" id="UltraMTBTrace" type="radio" value="Trace" v-model="XpertMTBRIFUltraMTB" name="UltraMTBTrace">
                    <label for="UltraMTBTrace">Trace</label>
                </div>
            </div>
        </div>

        <div class="field is-horizontal" v-if="XpertMTBRIFUltra == 'Yes'">
            <div class="field-label is-normal">
                <label class="label">Rif Resistant :</label>
            </div>
            <div class="field-body"  id="hi_test">
                <div class="field has-text-left">
                    <input class="is-checkradio" id="UltraRifResDetected" type="radio" value="Detected" v-model="XpertMTBRIFUltraRifRes" name="UltraRifResDetected">
                    <label for="UltraRifResDetected">Detected</label>
                    <input class="is-checkradio" id="UltraRifResNotDetected" type="radio" value="Not Detected" v-model="XpertMTBRIFUltraRifRes" name="UltraRifResNotDetected">
                    <label for="UltraRifResNotDetected">Not Detected</label>
                    <input class="is-checkradio" id="UltraRifResNotIndeterminate" type="radio" value="Indeterminate" v-model="XpertMTBRIFUltraRifRes" name="UltraRifResNotIndeterminate">
                    <label for="UltraRifResNotIndeterminate">Indeterminate</label>
                </div>
            </div>
        </div>

        <div class="field is-horizontal" style="margin-top:1.5em;">
            <div class="field-label is-normal">
                <label class="label">Genotype MTBDRPlus</label>
            </div>
            <div class="field-body">
                <div id="status" class="field-body">
                <div class="label is-normal">
                </div>

                <div class="field">
                    <input class="is-checkradio" id="GenotypeMTBDRplusYes" type="radio" value="Yes" v-model="GenotypeMTBDRplus" name="GenotypeMTBDRplusYes">
                    <label for="GenotypeMTBDRplusYes">Yes</label>
                    <input class="is-checkradio" id="GenotypeMTBDRplusNo" type="radio" value="No" v-model="GenotypeMTBDRplus" name="GenotypeMTBDRplusNo">
                    <label for="GenotypeMTBDRplusNo">No</label>
                    <input class="is-checkradio" id="GenotypeMTBDRplusNotDone" type="radio" value="Not Done" v-model="GenotypeMTBDRplus" name="GenotypeMTBDRplusNotDone">
                    <label for="GenotypeMTBDRplusNotDone">Not Done</label>
                </div>
                </div>
            </div>
        </div>
        <div class="field is-horizontal" v-if="GenotypeMTBDRplus == 'Yes'">
            <div class="field-label is-normal">
                <label class="label">rpoB :</label>
            </div>
            <div class="field-body"  id="hi_test">
                <div class="field has-text-left">
                    <input class="input" id="GenotypeMTBDR_rpoB" type="text" v-model="GenotypeMTBDR_rpoB" name="GenotypeMTBDR_inhA">
                </div>
            </div>
        </div>
        <div class="field is-horizontal" v-if="GenotypeMTBDRplus == 'Yes'">
            <div class="field-label is-normal">
                <label class="label">inhA :</label>
            </div>
            <div class="field-body"  id="hi_test">
                <div class="field has-text-left">
                    <input class="input" id="GenotypeMTBDR_inhA" type="text" v-model="GenotypeMTBDR_inhA" name="GenotypeMTBDR_inhA">
                </div>
            </div>
        </div>
        <div class="field is-horizontal" v-if="GenotypeMTBDRplus == 'Yes'">
            <div class="field-label is-normal">
                <label class="label">KatG :</label>
            </div>
            <div class="field-body"  id="hi_test">
                <div class="field has-text-left">
                    <input class="input" id="GenotypeMTBDR_KatG" type="text" v-model="GenotypeMTBDR_KatG" name="GenotypeMTBDR_KatG">
                </div>
            </div>
        </div>

        <div class="field is-horizontal" style="margin-top:1.5em;">
            <div class="field-label is-normal">
                <label class="label">Genotype MTBDRsl V1.0</label>
            </div>
            <div class="field-body">
                <div id="status" class="field-body">
                <div class="label is-normal">
                </div>

                <div class="field">
                    <input class="is-checkradio" id="GenotypeMTBDR_Rsl_V1Yes" type="radio" value="Yes" v-model="GenotypeMTBDR_Rsl_V1" name="GenotypeMTBDR_Rsl_V1Yes">
                    <label for="GenotypeMTBDR_Rsl_V1Yes">Yes</label>
                    <input class="is-checkradio" id="GenotypeMTBDR_Rsl_V1No" type="radio" value="No" v-model="GenotypeMTBDR_Rsl_V1" name="GenotypeMTBDR_Rsl_V1No">
                    <label for="GenotypeMTBDR_Rsl_V1No">No</label>
                    <input class="is-checkradio" id="GenotypeMTBDR_Rsl_V1NotDone" type="radio" value="Not Done" v-model="GenotypeMTBDR_Rsl_V1" name="GenotypeMTBDR_Rsl_V1NotDone">
                    <label for="GenotypeMTBDR_Rsl_V1NotDone">Not Done</label>
                </div>
                </div>
            </div>
        </div>
        <div class="field is-horizontal" v-if="GenotypeMTBDR_Rsl_V1 == 'Yes'">
            <div class="field-label is-normal">
                <label class="label">gyrA :</label>
            </div>
            <div class="field-body"  id="hi_test">
                <div class="field has-text-left">
                    <input class="input" id="GenotypeMTBDR_Rsl_V1_gyrA" type="text" v-model="GenotypeMTBDR_Rsl_V1_gyrA" name="GenotypeMTBDR_Rsl_V1_gyrA">
                </div>
            </div>
        </div>
        <div class="field is-horizontal" v-if="GenotypeMTBDR_Rsl_V1 == 'Yes'">
            <div class="field-label is-normal">
                <label class="label">rrs :</label>
            </div>
            <div class="field-body"  id="hi_test">
                <div class="field has-text-left">
                    <input class="input" id="GenotypeMTBDR_Rsl_V1_rrs" type="text" v-model="GenotypeMTBDR_Rsl_V1_rrs" name="GenotypeMTBDR_Rsl_V1_rrs">
                </div>
            </div>
        </div>
        <div class="field is-horizontal" v-if="GenotypeMTBDR_Rsl_V1 == 'Yes'">
            <div class="field-label is-normal">
                <label class="label">embB :</label>
            </div>
            <div class="field-body"  id="hi_test">
                <div class="field has-text-left">
                    <input class="input" id="GenotypeMTBDR_Rsl_V1_embB" type="text" v-model="GenotypeMTBDR_Rsl_V1_embB" name="GenotypeMTBDR_Rsl_V1_embB">
                </div>
            </div>
        </div>
        <div class="field is-horizontal" style="margin-top:1.5em;">
            <div class="field-label is-normal">
                <label class="label">Genotype MTBDRsl V2.0</label>
            </div>
            <div class="field-body">
                <div id="status" class="field-body">
                <div class="label is-normal">
                </div>

                <div class="field">
                    <input class="is-checkradio" id="GenotypeMTBDR_Rsl_V2Yes" type="radio" value="Yes" v-model="GenotypeMTBDR_Rsl_V2" name="GenotypeMTBDR_Rsl_V2Yes">
                    <label for="GenotypeMTBDR_Rsl_V2Yes">Yes</label>
                    <input class="is-checkradio" id="GenotypeMTBDR_Rsl_V2No" type="radio" value="No" v-model="GenotypeMTBDR_Rsl_V2" name="GenotypeMTBDR_Rsl_V2Yes">
                    <label for="GenotypeMTBDR_Rsl_V2No">No</label>
                    <input class="is-checkradio" id="GenotypeMTBDR_Rsl_V2NotDone" type="radio" value="Not Done" v-model="GenotypeMTBDR_Rsl_V2" name="GenotypeMTBDR_Rsl_V2NotDone">
                    <label for="GenotypeMTBDR_Rsl_V2NotDone">Not Done</label>
                </div>
                </div>
            </div>
        </div>
        <div class="field is-horizontal" v-if="GenotypeMTBDR_Rsl_V2 == 'Yes'">
            <div class="field-label is-normal">
                <label class="label">gyrA :</label>
            </div>
            <div class="field-body"  id="hi_test">
                <div class="field has-text-left">
                    <input class="input" id="GenotypeMTBDR_Rsl_V2_gyrA" type="text" v-model="GenotypeMTBDR_Rsl_V2_gyrA" name="GenotypeMTBDR_Rsl_V2_gyrA">
                </div>
            </div>
        </div>
        <div class="field is-horizontal" v-if="GenotypeMTBDR_Rsl_V2 == 'Yes'">
            <div class="field-label is-normal">
                <label class="label">gyrB :</label>
            </div>
            <div class="field-body"  id="hi_test">
                <div class="field has-text-left">
                    <input class="input" id="GenotypeMTBDR_Rsl_V2_gyrB" type="text" v-model="GenotypeMTBDR_Rsl_V2_gyrB" name="GenotypeMTBDR_Rsl_V2_gyrB">
                </div>
            </div>
        </div>
        <div class="field is-horizontal" v-if="GenotypeMTBDR_Rsl_V2 == 'Yes'">
            <div class="field-label is-normal">
                <label class="label">rrs :</label>
            </div>
            <div class="field-body"  id="hi_test">
                <div class="field has-text-left">
                    <input class="input" id="GenotypeMTBDR_Rsl_V2_rrs" type="text" v-model="GenotypeMTBDR_Rsl_V2_rrs" name="GenotypeMTBDR_Rsl_V2_rrs">
                </div>
            </div>
        </div>
        <div class="field is-horizontal" v-if="GenotypeMTBDR_Rsl_V2 == 'Yes'">
            <div class="field-label is-normal">
                <label class="label">eis :</label>
            </div>
            <div class="field-body"  id="hi_test">
                <div class="field has-text-left">
                    <input class="input" id="GenotypeMTBDR_Rsl_V2_eis" type="text" v-model="GenotypeMTBDR_Rsl_V2_eis" name="GenotypeMTBDR_Rsl_V2_eis">
                </div>
            </div>
        </div>

        <div class="field is-horizontal" >
            <div class="field-label is-normal">
                <label class="label">Whole genome sequencing report:</label>
            </div>
            <div class="field-body"  id="hi_test">
                <div class="label is-normal">
                </div>

                <div class="field ">
                    <input class="is-checkradio" id="isWholeGenomeYes" type="radio" value="Yes" v-model="isWholeGenome" name="isWholeGenomeYes">
                    <label for="isWholeGenomeYes">Yes</label>
                    <input class="is-checkradio" id="isWholeGenomeNo" type="radio" value="No" v-model="isWholeGenome" name="isWholeGenomeNo">
                    <label for="isWholeGenomeNo">No</label>
                    <input class="is-checkradio" id="isWholeGenomeNotDone" type="radio" value="Not Done" v-model="isWholeGenome" name="isWholeGenomeNotDone">
                    <label for="isWholeGenomeNotDone">Not Done</label>
                </div>
            </div>
        </div>

        <div v-if="isWholeGenome== 'Yes'" class="field is-horizontal" >
            <div class="field-label is-normal">
            </div>
            <div class="field-body"  id="hi_test">
                <div class="field has-text-left">
                    <input class="input" id="WholeGenome" type="text" v-model="WholeGenome" name="WholeGenome">
                </div>
            </div>
        </div>

     </div>
   </template>,
<script>
export default {
    name: 'MolecularTest',
    data() {
        return {
            XpertMTBRIF : '',
            XpertMTBRIFMTB:'',
            XpertMTBRIFRifRes:'',
            XpertMTBRIFUltra:'',
            XpertMTBRIFUltraMTB:'',
            XpertMTBRIFUltraRifRes:'',
            GenotypeMTBDRplus:'',
            GenotypeMTBDR_rpoB:'',
            GenotypeMTBDR_inhA:'',
            GenotypeMTBDR_KatG:'',
            GenotypeMTBDR_Rsl_V1:'',
            GenotypeMTBDR_Rsl_V1_gyrA:'',
            GenotypeMTBDR_Rsl_V1_rrs:'',
            GenotypeMTBDR_Rsl_V1_embB:'',
            GenotypeMTBDR_Rsl_V2: '',
            GenotypeMTBDR_Rsl_V2_gyrA:'',
            GenotypeMTBDR_Rsl_V2_gyrB:'',
            GenotypeMTBDR_Rsl_V2_rrs:'',
            GenotypeMTBDR_Rsl_V2_eis:'',
            isWholeGenome:'',
            WholeGenome:''
        }
    }
}
</script>

