import Vue from 'vue'
import Router from 'vue-router'
import VCalendar from 'v-calendar'
import AuthService from '../services/auth.service'
import About from '@/components/About.vue'
import Dashboard from '@/components/Dashboard.vue'
import Login from '@/components/Login.vue'
import SummaryRequest from '@/components/SummaryRequest.vue'
import SubmissionForm from '@/components/FormSubmission.vue'
import NewUser from '@/components/NewUser.vue'
import Submitted from '@/components/Submitted.vue'
import store from '../store'

Vue.use(Router)
Vue.use(VCalendar)


export default new Router({
  mode: 'history',
  base: __dirname,
  routes: [
    { path: '/about', component: About },
    { path: '/dashboard', component: Dashboard, beforeEnter: requireAuth },
    { path: '/summary', component: SummaryRequest, beforeEnter: requireAuth },
    { path: '/submission', component: SubmissionForm, beforeEnter: requireAuth },
    { path: '/', component: NewUser},
    { path: '/submitted', component:Submitted, beforeEnter: requireAuth},
    { path: '/login', component: Login },
    { path: '/logout',
      beforeEnter (to, from, next) {
        AuthService.logout()
        next(window.location.href = '/')
      }
    }
  ]
})


function requireAuth (to, from, next) {
  // Update to use Vuex store
  if (!store.getters['auth/isLogged']) {
    next({
      path: '/login',  // Changed from '/' to '/login' for better UX
      query: { redirect: to.fullPath }
    })
  } else {
    next()
  }
}