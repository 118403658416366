<template>
  <div id="app">
    <nav class="navbar" role="navigation" style="margin-top:10px;" aria-label="main navigation" v-if="loggedIn">
      <div class="navbar-brand">
      </div>
      <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
      <div id="navbarApplication" class="navbar-menu">
        <div class="navbar-start">
          <router-link class="navbar-item" to="/about">About</router-link>
          <router-link class="navbar-item" to="/summary">Summary</router-link>
          <router-link class="navbar-item" to="/submission">Submit New Case</router-link>
          <router-link class="navbar-item" to="/logout">Log out</router-link>
        </div>
      </div>
      <div class="navbar-end">
          <i class="fas fa-user-circle"></i>
          {{name}}
      </div>
    </nav>
    <template v-if="$route.matched.length">
      <router-view></router-view>
      
    </template>

    <template v-else>      
      {{$route.matched.length}}
      <p>You are logged {{ loggedIn ? 'in' : 'out' }}</p>
    </template>
    <div  style="text-align:center;margin-top:15px;">
      {{nameApp}} - {{environment}} - {{versionApp}}/{{versionBack}}
    </div>

  </div>
</template>

<script>
  import AuthService from './services/auth.service'

  import axios from 'axios'
  export default {
    data () {
      return {
        loggedIn: AuthService.isLogged(),
        name: AuthService.getName(),
        environment: process.env.VUE_APP_ENVIRONMENT,
        nameApp: process.env.VUE_APP_TITLE,
        versionApp: process.env.VUE_APP_VERSION,
        versionBack:''
      }
    },
    created () {
      AuthService.onChange = loggedIn => {
        this.loggedIn = loggedIn
      }
      this.getVersion();
    },methods:{
      async getVersion(){
        function handleError(err) {
          console.log(err);
        }
        axios.get(process.env.VUE_APP_API_SERVICE + '/version')
          .then(response => {
            const parsed = response.data.version;
            this.versionBack = parsed;

          }).catch(handleError)
      }

    }
  }
</script>
<style>
  html, body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #2c3e50;
  }
  #app {
    padding: 0 20px;
  }
  ul {
    line-height: 1.5em;
    padding-left: 1.5em;
  }
  a {
    color: #7f8c8d;
    text-decoration: none;
  }
  a:hover {
    color: #4fc08d;
  }
</style>